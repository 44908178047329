/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Auth Provider for ALEF.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { AuthService } from '../services/auth';
import { User } from '../model/user';
import { getLogger } from '../config/LogConfig';
import { useCookies } from 'react-cookie';
import { useConfig } from './Config';

interface AuthContextType {
  user: User | null;
  login: (user: string, password: string, success: (result: User) => void, failure: (err: string) => void) => void;
  logout: (success: VoidFunction, failure: VoidFunction) => void;
  restore_session: (success: VoidFunction, failure: VoidFunction) => void;
  signup: (first_name: string, last_name: string, user_id: string, email: string, password: string, success: (msg: string) => void, failure: (err: string) => void) => void
  confirm: (token: string, success: (msg: string) => void, failure: (err: string) => void) => void
  resendConfirmation: (username: string, success: (msg: string) => void, failure: (err: string) => void) => void
  changePassword: (curPass: string, newPass: string, success: (msg: string) => void, failure: (err: string) => void) => void
  makePasswordResetLink: (email: string, success: (msg: string) => void, failure: (err: string) => void) => void
  resetPassword: (newPassword: string, token: string, success: (msg: string) => void, failure: (err: string) => void) => void
  isAdmin: boolean
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { config } = useConfig();
  const auth_service = React.useMemo(() => new AuthService(config), [config]);
  const log = getLogger('providers.Auth');
  log.info(`Have config: ${JSON.stringify(config)}`)
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies();
  let [user, setUser] = React.useState<User | null>(null);
  
  let login = (user_id: string, password: string, success: (result: User) => void, failure: (err: string) => void) => {
    log.info(`Have config: ${JSON.stringify(config)}`)
    log.debug(`Start login for ${user_id}`);
    return auth_service.login(user_id, password,
      (result: User) => {
        //log.info(`Login callback for ${user_id} got ${typeof(result)} -- ${JSON.stringify(result)}`);
        setUser(result);
        success(result);
      },
      (msg: string) => {
        setUser(null);
        failure(msg);
      });
  };
  
  let signup = (first_name: string, last_name: string, user_id: string, email: string, password: string,
                success: (msg: string) => void, failure: (err: string) => void) => {
    log.debug(`Start signup for ${user_id}`);
    return auth_service.signup(first_name, last_name, user_id, email, password,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      });
  }
  
  let confirm = (token: string, success: (msg: string) => void, failure: (err: string) => void) => {
    log.debug('Start confirmation')
    return auth_service.confirm(token,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      })
  }
  
  let resendConfirmation = (username: string, success: (msg: string) => void, failure: (err: string) => void) => {
    log.debug('Start resend confirmation')
    return auth_service.resendConfirmation(username,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      })
  }
  
  
  let logout = (success: VoidFunction, failure: VoidFunction) => {
    log.info('Start logout');
    return auth_service.logout(
      () => {
        setUser(null);
        success();
      },
      () => {
        setUser(null);
        failure();
      }
    );
  };
  
  let restore_session = (success: VoidFunction, failure: VoidFunction) => {
    
    const cookie = cookies && cookies.alef_session ? cookies.alef_session : '';
    log.info(`Start restore_session. Cookie: ${cookie}`);
    log.info(`Have config: ${JSON.stringify(config)}`)
    auth_service.restore_session(
      (my_user: User) => {
        log.info(`Restore session callback: ${JSON.stringify(my_user)}`);
        setUser(my_user);
        success();
      },
      (msg: string) => {
        setUser(null);
        failure();
      }
    );
  };
  
  let changePassword = (curPass: string, newPass: string, success: (msg: string) => void, failure: (err: string) => void) => {
    log.info('Start change password service call')
    auth_service.changePassword(curPass, newPass,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      }
    )
  }
  let makePasswordResetLink = (email: string, success: (msg: string) => void, failure: (err: string) => void) => {
    log.info('Start make password reset link service call')
    auth_service.makeResetPasswordLink(email,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      }
    )
  }
  
  let resetPassword = (newPassword: string, token: string, success: (msg: string) => void, failure: (err: string) => void) => {
    log.info('Start change password service call')
    auth_service.resetPassword(newPassword, token,
      (msg: string) => {
        success(msg);
      },
      (msg: string) => {
        failure(msg);
      }
    )
  }
  
  let isAdmin = user?.roles?.includes('adm') ?? false
  
  let value = {
    user, login, logout, restore_session, signup, confirm, resendConfirmation, changePassword,
    makePasswordResetLink, resetPassword, isAdmin
  };
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
