/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * ALEF Theme
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    //   primary: {
    //     light: "#a9b4d7",
    //     main: "#363d46",
    //     dark: "#2B3038",
    //     contrastText: "white",
    //   },
    //   secondary: {
    //     main: "#44484D",
    //     dark: "#121315",
    //     light: "#47538d",
    //   },
    //   background: {
    //     default: "#ffffff",
    //   },
    //   info: {
    //     main: "#c90006",
    //   },
    //   mode: "dark",
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    //secondary: {
    //  main: "#44484D",
//      dark: "#121315",
  //    light: "#47538d",
    //},
    //secondary: {
    //  main: 'rgb(220, 0, 78)',
    //},
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(85,112,150,0) 100%)'
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(180deg, #ffffff 0%, #CCCCCC 100%)'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          // 557096
          background: 'rgba(85,112,150,1)'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          '&.Mui-selected': {
            borderBottom: 'solid 2px #777;',
            borderRadius: '4px 4px 0 0'
          }
        },
      },
    }
  }
});
