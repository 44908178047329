/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Auth service for ALEF. Logging in, Signing up, handling password actions, etc.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import { doGet, doPost } from './util';
import { User } from '../model/user';
import { getLogger } from '../config/LogConfig';
import { DynamicConfig } from '../config/app_config';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface LoginData {
  user_id: string;
  password: string;
}

export class AuthService {
  private log = getLogger('services.auth');
  
  constructor(readonly config: DynamicConfig) {
  }
  
  public async login(user_id: string, password: string, success: (my_user: User) => void, failure: (msg: string) => void) {
    this.log.info(`Using ALEF service for login. Signin as ${user_id}`);
    doPost(`${this.config.apiUrl}/auth/login`, { user_id: user_id, password: password },
      (my_user: User) => success(my_user),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public restore_session(success: (my_user: User) => void, failure: (msg: string) => void): void {
    this.log.info(`Use ALEF service to restore session.`);
    doGet(`${this.config.apiUrl}/auth/restore_session`,
      (user_dat) => success(user_dat as User),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public logout(success: VoidFunction, failure: VoidFunction): void {
    this.log.info(`Use ALEF service to logout.`);
    doGet(`${this.config.apiUrl}/auth/logout`, () => success(), () => failure());
    this.log.info('Done with fetch!');
  }
  
  public signup(first_name: string, last_name: string, user_id: string, email: string, password: string,
                success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Using ALEF service for signup. Signing up as ${user_id}`);
    doPost(`${this.config.apiUrl}/auth/signup`, {
        first_name: first_name,
        last_name: last_name,
        user_id: user_id,
        email: email,
        password: password
      },
      (msg: string) => success(msg),
      (msg: string) => failure(msg));
    this.log.info('Done with post!');
  }
  
  public confirm(token: string, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Using ALEF service for user confirmation.`)
    doPost(`${this.config.apiUrl}/auth/confirm`, {
        token: token
      },
      (msg: string) => success(msg),
      (msg: string) => failure(msg));
    this.log.info('Done with post!');
  }
  
  
  public resendConfirmation(username: string, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Using ALEF service to resend user confirmation email`)
    doGet(`${this.config.apiUrl}/auth/resend_confirm?username=${username}`,
      (msg: string) => success(msg),
      (msg: string) => failure(msg));
    this.log.info('Done with post!');
  }
  
  
  public changePassword(curPass: string, newPass: string, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Using ALEF service to change user password`)
    doPost(`${this.config.apiUrl}/auth/change_password`, { curPass: curPass, newPass: newPass },
      (msg: string) => success(msg),
      (msg: string) => failure(msg)
    )
  }
  
  public makeResetPasswordLink(email: string, success: (msg: string) => void, failure: (msg: string) => void) {
    this.log.info(`Using ALEF service to send the user a password reset link`)
    doPost(`${this.config.apiUrl}/auth/make_reset_password_link`, { email: email },
      (msg: string) => success(msg),
      (msg: string) => failure(msg)
    )
  }
  
  public resetPassword(newPassword: string, token: string, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Using ALEF service to reset user password`)
    doPost(`${this.config.apiUrl}/auth/reset_password`, { new_password: newPassword, token: token },
      (msg: string) => success(msg),
      (msg: string) => failure(msg)
    )
  }
}
