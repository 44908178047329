/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Sortable game card component. Used in Cardsorter to render tiles that are draggable.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import React, { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Icon } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { DNDItemTypes } from './DNDItemTypes'
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CheatDropdown from './CheatDropdown';
import {Language, Tile} from '../../model/alef_model';
import { DeepPartial } from '../../model/cardsorter_model';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {getLogger} from "../../config/LogConfig";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GTranslateIcon from "@mui/icons-material/GTranslate";
import IconButton from "@mui/material/IconButton";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {styled} from "@mui/system";

type SortableGameCardProps = {
  tile: Tile
  updateTile: (tileId: number, updatedProperties: DeepPartial<Tile>) => void
  moveTile: (dragIndex: number, hoverIndex: number, insertBefore: boolean) => void
  inputRefs: React.MutableRefObject<Array<HTMLInputElement | null>>
  checked: boolean
  handleInsertNewTile: (right?: boolean, tile?: Tile) => void
  handleDeleteTile: (tile: Tile) => void
  handleEditTL: (tile: Tile, newTLTerm: string) => void
  tileOrder: string,
  isUIControlDisabled: boolean,
  tl_lang: Language
  tileIds: number[]
}

const TileMenu = styled('div')`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    flex-flow: nowrap;
    justify-items: flex-end;
    width: 100%;
`

const SortableGameCard: FC<SortableGameCardProps> = ({
                                                       tile,
                                                       updateTile,
                                                       moveTile,
                                                       inputRefs,
                                                       checked,
                                                       handleInsertNewTile,
                                                       handleDeleteTile,
                                                       handleEditTL,
                                                       tileOrder,
                                                       isUIControlDisabled,
                                                       tl_lang,
                                                       tileIds
                                                     }: SortableGameCardProps) => {

  const log = getLogger('components.SortableGameCard');
  const containerRef = useRef<HTMLDivElement>(null)
  const tileHandleRef = useRef<HTMLDivElement>(null)
  const TLInputRef = useRef<HTMLInputElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isEditingTL, setIsEditingTL] = React.useState(false)
  const [tempTLTerm, setTempTLTerm] = React.useState<string>(tile.tl_token?.term ?? '')
  const containerWidth = Math.max(tile.tl_token?.term.length, tempTLTerm.length) + 1
  
  
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (!isUIControlDisabled) {
      setAnchorEl(event.currentTarget);
    }
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleTileUpdate = (updatedProperties: DeepPartial<Tile>) => {
    updateTile(tile.id, updatedProperties)
  }

  const handleCopyToClipboard = () : void => {
    navigator.clipboard.writeText(tile.tl_token.term)
  }

  const handleCopyEnToClipboard = () : void => {
    navigator.clipboard.writeText(tile.e_token.term)
  }

  const handleWiktionaryLookup = () : void => {
    // log.info(`Lookup: https://en.wiktionary.org/wiki/${tile.tl_token}`);
    window.open(`https://en.wiktionary.org/wiki/${tile.tl_token.term}`)
  }

  const handleGoogleTranslateLookup = () : void => {
    // log.info(`https://translate.google.com/?sl=auto&tl=en&text=${tile.tl_token.term}`)
    window.open(`https://translate.google.com/?sl=${tl_lang.google_translate_iso_code}&tl=en&text=${tile.tl_token.term}`)
  }
  
  const menuOptions = [
    {
      icon: <FormatTextdirectionRToLIcon sx={{ mr: 0.5 }}/>,
      label: 'Insert tile left', onClick: () => {
        handleInsertNewTile(false, tile)
        handleClose()
      },
      is_lookup: false,
    },
    {
      icon: <FormatTextdirectionLToRIcon sx={{ mr: 0.5 }}/>,
      label: 'Insert tile right', onClick: () => {
        handleInsertNewTile(true, tile)
        handleClose()
      },
      is_lookup: false
    },
    {
      icon: <DeleteForeverIcon sx={{ mr: 0.5 }}/>,
      label: 'Delete Tile', onClick: () => {
        handleDeleteTile(tile)
        handleClose()
      },
      is_lookup: false
    },
    {
      icon: <EditIcon sx={{ mr: 0.5 }}/>,
      label: 'Edit TL', onClick: () => {
        setIsEditingTL(true)
        handleClose()
      },
      is_lookup: false
    },
    {
      icon: <Icon sx={{mr: 0.5}}>
        <img alt="Wiktionary Logo" src={process.env.PUBLIC_URL + 'img/En.wiktionary_favicon.svg'} width='25px' height='25px'/>
      </Icon>,
      label: 'Lookup in Wiktionary', onClick: () => {
        handleWiktionaryLookup()
        handleClose()
      },
      is_lookup: true
    },
    {
      icon: <GTranslateIcon sx={{mr: 0.5}}/>,
      label: 'Lookup in Google Translate', onClick: () => {
        handleGoogleTranslateLookup()
        handleClose()
      },
      is_lookup: true
    },
    {
      icon: <ContentCopyIcon sx={{mr: 0.5}}/>,
      label: 'Copy Source to Clipboard', onClick: () => {
        handleCopyToClipboard()
        handleClose()
      },
      is_lookup: true
    },
    {
      icon: <ContentCopyIcon sx={{mr: 0.5}}/>,
      label: 'Copy Translation to Clipboard', onClick: () => {
        handleCopyEnToClipboard()
        handleClose()
      },
      is_lookup: true
    }


  ]
  
  /**
   * Handle autoFocus of TLInput when setIsEditingTL is True.
   */
  React.useEffect(() => {
    if (isEditingTL && TLInputRef.current) {
      TLInputRef.current.focus()
    }
  }, [isEditingTL])
  
  const handleDoneEditing = () => {
    setIsEditingTL(false)
    if (!tempTLTerm || tempTLTerm === '') {
      handleEditTL(tile, '◇')
      setTempTLTerm('◇')
    } else {
      handleEditTL(tile, tempTLTerm)
    }
  }

  const isDragDisabled = () => { return tileOrder === 'tl' }
  
  const [{ handlerId }, drop] = useDrop<Tile, void, { handlerId: Identifier | null }>({
    accept: DNDItemTypes.SORTABLE_CARD,
    canDrop: (item, monitor) => !isDragDisabled(),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(dragItem: Tile, monitor) {
      if (!containerRef.current) {
        log.info(`Return early due to !ref.current`)
        return
      }
      const dragIndex = tileIds.indexOf(dragItem.id)
      const hoverIndex = tileIds.indexOf(tile.id)
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        //log.info(`Return early due to DI==hi di=${dragIndex}, hi=${hoverIndex}`);
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = containerRef.current?.getBoundingClientRect()
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverMiddleX: number = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // log.info(hoverMiddleX)
      const hoverClientX: number = (clientOffset as XYCoord).x - hoverBoundingRect.left

      const beforeHalf = (
        (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) ||   // Dragging from left OR
        (dragIndex > hoverIndex && hoverClientX > hoverMiddleX));    // Dragging from right
      const onNextCard = Math.abs(dragIndex - hoverIndex) === 1
      if (onNextCard && beforeHalf) {
        // log.info(`Return early due to 3: di=${dragIndex}, hi=${hoverIndex}, h client x=${hoverClientX}, hover middle x=${hoverMiddleX}, client offset=${(clientOffset as XYCoord).x}`);
        return;
      }

      // Time to actually perform the action
      moveTile(dragIndex, hoverIndex, beforeHalf);
    },
    drop: (dragItem: Tile, monitor) => {
      // Use This?
    }
  })
  
  const [{ isDragging }, drag, preview] = useDrag({
    type: DNDItemTypes.SORTABLE_CARD,
    canDrag: !isDragDisabled(),
    item: (): Tile => {
      return tile // Return the tile that is being hovered over
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: Tile, monitor) => {
      if (item && monitor) {
        // TODO: Use this to update the tile information after drag/drop has completed?
      }
    }
  });
  
  const opacity = isDragging ? 0.2 : 1

  if (tileHandleRef.current) {
    // Changing to use the container ref. The handleRef will be the actual handle icon. When using this, it
    // will only allow dropping on the handle icon.
    drop(drag(tileHandleRef.current))
    preview(containerRef.current)
  }

  const cursorStyle = isDragDisabled() ? 'default' : (isDragging ? 'move' : 'grab')
  
  const renderUserWord = () => {
    return tile.locked ? (
        <Button onClick={() => handleTileUpdate({ locked: false })}
                sx={{
                  color: 'black',
                  borderRadius: '5px',
                  padding: 0,
                  textTransform: 'none',
                  minWidth: '2em',
                  width: '100%',
                  height: '100%'
                }}
                data-testid={`user-word-button-${tile.id}`}
                disabled={isUIControlDisabled}
        >
          <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                color: 'gray',
                marginTop: '1.5px',
                marginBottom: '1.5px',
                whiteSpace: 'pre',
                minHeight: '25px'
              }}
              data-testid={`user-word-typ-${tile.id}`}
          >
            {tile.e_token?.term === '' ? '◇' : tile.e_token?.term ?? '◇'}
          </Typography>
        </Button>
    ) : (
        <CheatDropdown
            tile={tile}
            inputRefs={inputRefs}
            handleTileUpdate={handleTileUpdate}
            textFieldValue={!tile.e_token ? '' : tile.e_token.term}
            checked={checked}
            tileOrder={tileOrder}
            isUIControlDisabled={isUIControlDisabled}
        />
    )
  }
  
  const renderContextMenu = (
    <Menu
      id="options-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid={`context-menu-${tile.id}`}
    >
      {menuOptions.filter((option) => {
        return !option.is_lookup || (!tile.tl_token?.is_punct);
      }).map((option, index) => (
        <MenuItem
          key={option.label}
          onClick={option.onClick}
        >
          {option.icon}
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <Grid style={{ opacity, justifyContent: 'flex-start' }} margin="3px"
          data-testid={`sortable-game-card-${tile.id}`}
    >
      <Container ref={containerRef}
        disableGutters
        data-testid={`sortable-game-card-container-${tile.id}`}
        sx={{
          backgroundColor: isHovered ? 'rgba(167, 199, 250,0.2)' : tile.modified ? '#ffffaa' : 'transparent',
          outline: isHovered ? '1px solid rgba(45,45,45,0.2)' : tile.modified ? '1px solid #aaaa00' : '1px solid rgba(45,45,45,0.1)',
          margin: '1px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          padding: '.2em',
          alignItems: 'center',
          "& .menu-icon, & .drag-icon": {
            color: 'transparent',
            transition: 'all 0.25s ease-in'
          },
          "&:hover": {
            "& .menu-icon": {
              color: 'secondary.main',
            },
            "& .drag-icon": {
              color: 'secondary.main',
            }
          },

          "&:active": {
            "& .menu-icon": {
              color: 'secondary.main'
            },
            "& .drag-icon": {
              color: 'secondary.main'
            }
          },
          "&:focus": {
            "& .menu-icon": {
              color: 'secondary.main'
            },
            "& .drag-icon": {
              color: 'secondary.main'
            }
          },
        }}>
        <TileMenu ref={tileHandleRef} data-handler-id={handlerId}>
          { !isDragDisabled() && /* */
            <Icon fontSize="small" color="secondary" className="drag-icon"
                  sx={{pt: '5px', pb: '5px', cursor: cursorStyle }}>
              <DragHandleIcon/>
            </Icon>
          }
          <IconButton size="small" color="secondary" sx={{ml: 'auto'}}
                      className={anchorEl != null ? "" : "menu-icon"}
                      data-testid={`sortable-game-card-menu-${tile.id}`}
                      onClick={handleOpen}>
            <MoreVertIcon/>
          </IconButton>
        </TileMenu>
        <Container
          disableGutters>
          {isEditingTL ? <TextField
            inputRef={TLInputRef}
            value={tempTLTerm === '◇' ? '' : tempTLTerm }
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0px!important',
                width: `${containerWidth + 1}ch`
              },
              '& .MuiInputBase-input': {
                padding: '2px 4px 2px 6px!important'
              }
            }}
            onChange={(e) => {
              setTempTLTerm(e.target.value)
            }}
            onBlur={handleDoneEditing}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleDoneEditing()
              } else if (e.key === 'Escape') {
                setIsEditingTL(false)
              }
            }}
            dir={tl_lang.rtl ? 'rtl' : 'ltr'}
            inputProps={{ 'data-testid': `tl-input-${tile.id}` }}
            data-testid={`tl-text-field-${tile.id}`}
          /> : <Typography
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            variant="h5" component="div"
            sx={{
              direction: tl_lang.rtl ? 'rtl' : 'ltr',
              fontSize: '1em',

              width: '100%',
              textAlign: 'center',
              whiteSpace: 'pre'
            }}
            data-testid={`tl-typo-${tile.id}`}
          >
            {tile.tl_token?.term}
          </Typography>}</Container>
        
        {renderUserWord()}
        {renderContextMenu}

        {/* Uncomment the following to show the ID and location details for debugging */}
        {/*<div>{'ID: ' + tile.id}</div>*/}
        {/*<div>{'tl_loc: ' + tile.tl_loc}</div>*/}
        {/*<div>{'en_loc: ' + tile.en_loc}</div>*/}
      </Container>
    </Grid>
  
  )
}

export { type SortableGameCardProps, SortableGameCard };
