/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The Document grid/table for ALEF.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getLogger } from '../config/LogConfig';
import Box from '@mui/material/Box';
import { renderLink } from './grid/renderers/LinkRenderer';
import { Document } from '../model/alef_model';
import { ContextMenu } from './menu/ContextMenu';
import { useConfig } from '../providers/Config';
import { DocumentService } from '../services/document_service';
import { useAuth } from '../providers/Auth';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface DocumentGridProps {
  documents: Document[];
  updateDocuments: (updatedDocuments: Document[]) => void;
  handleRemovedDocument: (id: number) => void;
}

const DocumentGrid = ({ documents, updateDocuments, handleRemovedDocument }: DocumentGridProps) => {
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const log = getLogger('components.DocumentGrid');
  log.info('Making document grid!');
  const auth = useAuth()
  
  // TODO we should wire this in if needed
  // If so we need to add onCellClick={handleEvent} to DataGrid below.
  // const handleEvent: GridEventListener<'cellClick'> = (params,  // GridCellParams
  //                                                       event,   // MuiEvent<React.MouseEvent<HTMLElement>>
  //                                                       details, // GridCallbackDetails
  //  ) => {
  //    log.info("Got a click")
  //  }
  
  /**
   * Returns boolean for whether the current user is the owner of the document specified by the doc_id
   * @param docId the document id that is being evaluated
   */
  const isOwner = React.useCallback((docId: number): boolean => {
    if (!documents) return false
    const document: Document | undefined = documents.find((doc: Document) => doc.id === docId)
    return (document && document.created_user_id === auth.user?.id) ?? false
  }, [auth.user?.id, documents])
  
  /**
   * Updates a single document specified by the docId. Sets the public field to the opposite of what is seen in the client.
   * Upon success the documents array is updated in the client and the state is rerendered.
   * @param docId the document id of the document to update
   */
  const handleMakePublic = (docId: number): void => {
    const id: number = documents.findIndex((doc: Document): boolean => doc.id === docId);
    if (id === -1) {
      log.error('Document to make public/private not found within documents array')
      return
    }
    const documentToUpdate: Document = { ...documents[id] } // Get non-reference to object
    const index: number = id
    documentToUpdate.public = !documentToUpdate.public // update the cloned object's public field to opposite
    doc_service.documentUpdate(documentToUpdate,
      (msg: string): void => {
        log.info('Successfully updated the document in the database.', msg)
        // Update document in documents upon success
        const updatedDocuments: Document[] = [...documents]
        updatedDocuments[index] = documentToUpdate
        updateDocuments(updatedDocuments)
      }, (msg: string): void => {
        log.info('Failure to update document.', msg)
      })
  }
  
  /**
   * Memorized component for rendering the cell in the data grid that contains the context menu button.
   */
  const RowMenuCell = React.memo((params: GridRenderCellParams<Document>) => {
    const [rowMenuOpen, setRowMenuOpen] = React.useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    return (
      <>
        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
          setRowMenuOpen(true)
        }} size="small">
          <MoreVertIcon/>
        </IconButton>
        {rowMenuOpen &&
          <ContextMenu
            doc={params.row}
            anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDelete={handleDelete}
            isAdmin={auth.isAdmin} isOwner={isOwner(params.row.id)}
            makePublic={handleMakePublic}/>}
      </>
    )
  })
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 70 },
    {
      field: 'language',
      headerName: 'Language',
      minWidth: 180,
      flex: 1,
      valueFormatter: ({ value }) => value.name,
      valueGetter: (params) => params.row.language.name,
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 400,
      flex: 1,
      renderCell: (params) => renderLink('', '/translation/1', params),
    },
    { field: 'author', headerName: 'Author', minWidth: 180, flex: 1 },
    { field: 'description', headerName: 'Description', minWidth: 300, flex: 2 },
    { field: 'doc_type', headerName: 'Type', minWidth: 180, flex: 1 },
    {
      field: 'created_at', headerName: 'Created On', minWidth: 120, flex: 1, renderCell: (params) => {
        const date: Date = new Date(params.row.created_at)
        const formattedDate: string = date.toLocaleDateString('en-US')
        return (<div>{formattedDate}</div>)
      }
    },
    { field: 'public', headerName: 'Public', minWidth: 100, flex: 1 },
    { field: 'comment', headerName: 'Comment', minWidth: 280, flex: 1 },
    { field: 'source', headerName: 'Source', minWidth: 180, flex: 1 },
    {
      field: 'menu', headerName: '', sortable: false, filterable: false, width: 50, hideable: false,
      renderCell: (params: GridRenderCellParams<any, any, any>) => <RowMenuCell {...params}/>
    },
  ];
  
  const handleDelete = (id: number) => {
    log.info('test delete on document with id: ' + id)
    doc_service.deleteDocument(id,
      (result: string) => {
        log.info(`Successfully deleted document with id ${id}; ${result}`)
        handleRemovedDocument(id)
      },
      (result: string) => {
        log.error(`Failed to delete document with id ${id}; ${result}`)
      })
  }
  
  return (
    <>
      <Box sx={{ height: '65vh', width: '100%' }}>
        <DataGrid rows={documents}
                  sx={{
                    '& .alef-row>div': {
                      pt: '10px',
                      pb: '10px'
                    }}}
                  getRowHeight={() => 'auto'}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        created_at: false,
                        public: false,
                        comment: true,
                        source: false
                      },
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPage) => setPageSize(newPage)}
                  getRowClassName={(params) => `alef-row`}
                  columns={columns}
                  density={'comfortable'}
                  autoPageSize={false} disableSelectionOnClick
        />
      </Box>
    </>
  );
};

export default DocumentGrid;
