/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Utils for ALEF. GET Request, POST Request, etc.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import { getLogger } from '../config/LogConfig';

// Note this is not controlled by the production config.
const log = getLogger('services.util');

function doGet<T>(url: string, success: (result: T) => void, failure: (msg: string) => void): Promise<void> {
  // log.info('Doing some get');
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    credentials: 'include'
  }).then(r => {
      if (!r.ok) {
          throw new Error(`GET Response for ${url} was not OK! Got status: ${r.status} : ${r.statusText}`);
      }
      return r.json();
  }).then(r => {
    // log.info(`Need to process for ${url}! response : ${JSON.stringify(r)}`)
    if (r.error) {
      failure(r.error)
    } else {
      success(r)
    }
  }).catch(error => {
    log.error(`System error! ${error}`)
    failure('System error!')
  })
}

/* Do a post, returning either an object of the desired type, or a string. */
function doPost<D, T>(url: string, body_data: D, success: (result: T) => void, failure: (msg: string) => void): Promise<void> {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*'
    },
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(body_data),
  }).then(async response => {
    if (!response.ok) {
      throw new Error(`POST Response for ${url} was not OK! Got status: ${response.status} : ${response.statusText}`);
    }
    
    // log.info(`my type ${typeof response}`)
    // log.info("HEADERS")
    // response.headers.forEach((v, name) => log.info("     " + name + ": " + v));
    response.json().then(
      (json_dat) => {
        if (json_dat.error) {
          failure(json_dat.error)
        } else {
          // log.info(`Need to process for ${url}! response : ${JSON.stringify(json_dat)}`)
          success(json_dat)
        }
      },
      (failed) => {
        throw new Error(`Error processing json for ${url}! ${JSON.stringify(failed)}`);
      }
    );
  }).catch(error => {
    log.error(`System error! ${error}`)
    failure('System error!')
  });
}

export { doGet, doPost };
