/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Password dialog for changing a user's password.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '35ch'
  },
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface Passwords {
  curPass: string;
  newPass: string;
  retypePass: string;
}

const PasswordDialog = (params: any) => {
  const auth = useAuth()
  const nav = useNavigate()
  const [values, setValues] = React.useState<Passwords>({
    'curPass': '',
    'newPass': '',
    'retypePass': ''
  })
  const [err, setErr] = React.useState<String>('')
  
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setErr('')
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
  }
  
  const handleCancel = () => {
    params.setPasswordDialogOpen(false)
    setErr('')
    setValues({
      'curPass': '',
      'newPass': '',
      'retypePass': ''
    })
  }
  
  const validatePasswords = (): boolean => {
    // Make sure nothing is empty
    if (Object.values(values).some(v => v === '' || v === null)) {
      setErr('No fields may be empty!')
      return false
    }
    // Make sure new passwords match
    if (values.newPass !== values.retypePass) {
      setErr('New passwords do not match!')
      return false
    }
    // Make sure old and new passwords are not the same
    if (values.curPass === values.newPass) {
      setErr('New password cannot be the same as the previous.')
    }
    return true
  }
  
  const handleCallChangePassword = () => {
    if (!validatePasswords()) return;
    auth.changePassword(values.curPass, values.newPass,
      (msg) => {
        auth.logout(() => {
          nav('/')
        }, () => {
          nav('/')
        })
      },
      (msg) => {
        setErr(msg)
      })
  }
  
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleCallChangePassword()
    }
    if (event.key === 'Escape') {
      handleCancel()
    }
  }
  
  
  return (
    <Backdrop open={params.open} sx={{ overflowY: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleCancel}>
      
      <Dialog open={params.open} onKeyDown={handleKeyDown} disableRestoreFocus data-testid="password-dialog-component">
        <DialogContent onClick={(event) => event.stopPropagation()}>
          <DialogTitle sx={{ textAlign: 'center' }}>Change your Password</DialogTitle>
          <Container style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledTextField autoFocus required error={!!err} type="password" label="Current password" name="curPass"
                             value={values.curPass}
                             onChange={handleChange}
                             inputProps={{ 'data-testid': 'curPass' }}/>
            <StyledTextField required error={!!err} type="password" label="New password" name="newPass"
                             value={values.newPass}
                             onChange={handleChange}
                             inputProps={{ 'data-testid': 'newPass' }}/>
            <StyledTextField required error={!!err} type="password" label="Re-type new password" name="retypePass"
                             value={values.retypePass}
                             onChange={handleChange}
                             inputProps={{ 'data-testid': 'retypePass' }}/>
            {err && <FormHelperText error data-testid="error-text">{err}</FormHelperText>}
            <Container
              disableGutters
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <StyledButton variant="contained" onClick={handleCancel} data-testid="cancel-button">Cancel</StyledButton>
              <StyledButton variant="contained" onClick={handleCallChangePassword} data-testid="confirm-button">
                Change Password</StyledButton>
            </Container>
          </Container>
        </DialogContent>
      </Dialog>
    </Backdrop>
  )
}
export default PasswordDialog
