/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The Add Document view. A form that allows a user to add a new document to ALEF.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormHelperText, TextareaAutosize, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import { LanguageDocument } from '../../model/languageDocument';
import { DocumentService } from '../../services/document_service';
import Waiting from '../../components/Waiting';
import { useNavigate } from 'react-router-dom';
import { LanguageService } from '../../services/language_service';
import { Language } from '../../model/alef_model';
import { useConfig } from '../../providers/Config';
import Checkbox from '@mui/material/Checkbox';
import { getLogger } from '../../config/LogConfig';

type AddDocumentProps = {}

interface Option {
  id: number,
  label: string
}


const AddDocument: React.FC<AddDocumentProps> = () => {
  const log = getLogger('views.main.AddDocument');
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const lang_service = React.useMemo(() => new LanguageService(config), [config]);
  const [formValues, setFormValues] = useState<LanguageDocument>({
    e_lang: 'ENG',
    f_lang: 'SPA',
    version: 1,
    active: true,
    language: null,
    title: '',
    docType: null,
    description: '',
    targetLanguageText: '',
    notes: '',
    source: '',
    author: '',
    user: '',
    timestamp: -1,
    public: false
  })
  /* eslint-disable */ //TODO: Implement fetch document types from ref table and populate drop down
  const [documentTypeOptions, setDocumentTypeOptions] = useState<Option[]>([{ id: 1, label: 'test document type' }])
  const [adding, setAdding] = useState<boolean>(false)
  const nav = useNavigate()
  const [languageError, setLanguageError] = useState<string>('')
  const [docTypeError, setDocTypeError] = useState<string>('')
  const [titleError, setTitleError] = useState<string>('')
  const [authorError, setAuthorError] = useState<string>('')
  const [descriptionError, setDescriptionError] = useState<string>('')
  const [tlTextError, setTLTextError] = useState<string>('')
  const [languages, setLanguages] = useState<Language[]>([])
  
  useEffect(() => {
    lang_service.fetchLanguages().then(() => {
      const languages: Language[] = lang_service.getAllLanguages() ?? []
      if (languages.length > 0) setLanguages(languages)
    })
  }, [])
  
  // handle change of text fields
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
    setAuthorError('')
    setDescriptionError('')
    setTitleError('')
    setTLTextError('')
  }
  
  /**
   * Handle public checkbox onChange. Sets the public formValue field to what the checkbox has been set to.
   * @param e the change event of the checkbox
   */
  const handlePublicChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues: LanguageDocument) => ({
      ...prevValues,
      public: e.target.checked
    }))
  }
  
  // handle change of language autocomplete
  const handleLanguageChange = (e: React.ChangeEvent<{}>, option: Option | null) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      language: option || null
    }))
    setLanguageError('')
  }
  
  // handle change of document type autocomplete
  const handleDocumentTypeChange = (e: React.ChangeEvent<{}>, option: string | Option | null) => {
    if (!option) return;
    if (typeof (option) === 'string') {
      let newOption = { id: documentTypeOptions.length + 1, label: option }
      setFormValues((prevValues) => ({
        ...prevValues,
        docType: newOption || null
      }))
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        docType: option || null
      }))
    }
    setDocTypeError('')
  }
  
  const validateLanguage = (language: Option | null) => {
    if (!language) return 'Language cannot be empty.'
    return ''
  }
  
  const validateDocType = (docType: Option | null) => {
    if (!docType) return 'Document Type cannot be empty.'
    return ''
  }
  
  const validateSimpleStringField = (text: string, label: string) => {
    if (!text) return `${label} cannot be empty.`
    return ''
  }
  
  const validateForm = (formValues: LanguageDocument): boolean => {
    const languageError = validateLanguage(formValues.language)
    const docTypeError = validateDocType(formValues.docType)
    const titleError = validateSimpleStringField(formValues.title, 'Title')
    const authorError = validateSimpleStringField(formValues.author, 'Author')
    const descriptionError = validateSimpleStringField(formValues.description, 'Description')
    const tlTextError = validateSimpleStringField(formValues.targetLanguageText, 'Target Language Text')
    setLanguageError(languageError)
    setDocTypeError(docTypeError)
    setTitleError(titleError)
    setAuthorError(authorError)
    setDescriptionError(descriptionError)
    setTLTextError(tlTextError)
    return !(languageError || docTypeError || titleError || authorError || descriptionError || tlTextError)
  }
  
  const handleSubmit = () => {
    if (!validateForm(formValues)) return
    let document = formValues;
    setAdding(true)
    doc_service.documentAdd(document,
      (msg: string, docId: number) => {
        nav(`/documents/${docId}/translation/1`)
      },
      (msg) => {
        log.error('AN ERROR OCCURRED: ', msg)
      })
  }
  
  const getLanguageOptions = (): Option[] => {
    return languages.map(language => ({
      id: language.id,
      label: language.name
    }))
  }
  
  if (adding || !languages) {
    return <Waiting/>;
  }
  
  /**
   * Gets the class name from the selected language. Returns empty string if no language selected.
   */
  const getCssClassName = (): string => {
    const selectedLanguage: Language | undefined = languages.find((lang: Language) => lang.id === formValues.language?.id)
    return `${selectedLanguage?.name ?? ''} ${selectedLanguage?.css_classes ?? ''}`.trim()
  }
  
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ pb: '0.75em' }}>Add a Document</Typography>
      <Paper sx={{ p: '2em', width: '60%', marginBottom: '5em' }}>
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
          <Autocomplete
            data-testid={'language-autocomplete'}
            sx={{ width: '35%' }}
            options={getLanguageOptions()}
            value={formValues.language}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleLanguageChange}
            renderInput={(params) => (
              <TextField
                {...params} label={'Language *'} sx={{ mb: '0.5em' }}
                required={formValues.language?.label.length === 0}
                error={!!languageError}
                inputProps={{ ...params.inputProps, 'data-testid': 'language-input' }}
              />
            )}
          />
          {languageError && <FormHelperText error>{languageError}</FormHelperText>}
          
          <Autocomplete
            freeSolo
            sx={{ width: '35%' }}
            options={documentTypeOptions}
            value={formValues.docType}
            onInputChange={handleDocumentTypeChange}
            renderInput={(params) => (
              <TextField {...params} label={'Document Type *'} margin="normal"
                         required={formValues.docType?.label.length === 0}
                         error={!!docTypeError}
                         inputProps={{ ...params.inputProps, 'data-testid': 'doc-type-input' }}
              />
            )}
          />
          {docTypeError && <FormHelperText error>{docTypeError}</FormHelperText>}
          
          <TextField
            inputProps={{ 'data-testid': 'title-input' }}
            required
            sx={{ width: '50%' }}
            label="Document Title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            margin="normal"
            error={!!titleError}
          />
          {titleError && <FormHelperText error>{titleError}</FormHelperText>}
          
          <TextField
            required
            sx={{ width: '50%' }}
            label="Author"
            name="author"
            value={formValues.author}
            onChange={handleChange}
            margin="normal"
            error={!!authorError}
          />
          {authorError && <FormHelperText error>{authorError}</FormHelperText>}
          
          <TextField
            required
            sx={{ width: '100%' }}
            label="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            margin="normal"
            error={!!descriptionError}
          />
          {descriptionError && <FormHelperText error>{descriptionError}</FormHelperText>}
          
          <Typography sx={{ marginTop: '16px', color: tlTextError ? '#d32f2f' : '' }}> Target Language Text *</Typography>
          <TextareaAutosize
            required
            maxRows={30}
            placeholder="Target Language Text"
            name="targetLanguageText"
            value={formValues.targetLanguageText}
            onChange={handleChange}
            dir={languages.find((lang: Language) => lang.id === formValues.language?.id)?.rtl ? 'rtl' : 'ltr'}
            className={getCssClassName()}
            style={{
              overflow: 'auto',
              resize: 'vertical',
              width: '100%',
              height: '25em',
              minHeight: '8em',
              padding: '.5em',
              color: tlTextError ? '#d32f2f' : '',
              borderColor: tlTextError ? '#d32f2f' : ''
              
            }}
          />
          {tlTextError && <FormHelperText error>{tlTextError}</FormHelperText>}
          
          <Typography sx={{ marginTop: '16px' }}>Notes</Typography>
          <TextareaAutosize
            placeholder="Notes"
            name="notes"
            value={formValues.notes}
            onChange={handleChange}
            style={{
              overflow: 'auto',
              resize: 'vertical',
              width: '100%',
              height: '5em',
              minHeight: '4em',
              padding: '.5em'
            }}
          />
          <TextField
            sx={{ width: '100%' }}
            label={'Source https:// url'}
            name="source"
            value={formValues.source}
            onChange={handleChange}
            margin="normal"
            type={'url'}
          />
          <Box sx={{ marginTop: '0.25em' }}>
            <Button variant="contained" type="submit" sx={{ width: '8em', p: '0.5em' }}
                    onClick={handleSubmit}
            >
              Submit
            </Button>
            <FormControlLabel
              sx={{ marginInline: '1em' }}
              control={
                <Checkbox
                  name="public"
                  data-testid={'public-checkbox'}
                  checked={formValues.public}
                  value={formValues.public}
                  onChange={handlePublicChange}
                />}
              label="Public"
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default AddDocument;
