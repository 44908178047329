/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Account Settings for user profile page.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Container, FormLabel, Input } from '@mui/material';
import { User } from '../../../model/user'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PasswordDialog from './PasswordDialog';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%'
}));

interface AccountSettingsProps {
  user: User
}

const AccountSettings = ({ user }: AccountSettingsProps) => {
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false)
  
  if (!user) {
    return null
  }
  
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <StyledFormControl>
            <FormLabel>
              First Name
            </FormLabel>
            <Input placeholder={user.first_name}/>
          </StyledFormControl>
        </Grid>
        <Grid item xs={6}>
          
          <StyledFormControl>
            <FormLabel>
              Last Name
            </FormLabel>
            <Input placeholder={user.last_name}/>
          </StyledFormControl>
        </Grid>
        
        <Grid item xs={6}>
          <StyledFormControl>
            <FormLabel>
              User Id
            </FormLabel>
            <Input placeholder={user.user_id}/>
          </StyledFormControl>
        </Grid>
        
        <Grid item xs={6}>
          
          <StyledFormControl>
            <FormLabel>
              email
            </FormLabel>
            <Input placeholder={user.email}/>
          </StyledFormControl>
        </Grid>
        
        <Grid item xs={6}>
          <Button onClick={() => setPasswordDialogOpen(true)}>Change Password</Button>
          <PasswordDialog open={passwordDialogOpen} setPasswordDialogOpen={setPasswordDialogOpen}/>
        </Grid>
      
      </Grid>
    </Container>
  );
};

export default AccountSettings
