/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Document Service for ALEF. Handles all document actions defined within ALEF.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import { doGet, doPost } from './util';
import {
  Document,
  DocumentSegments,
  DocumentSegmentTiles,
  DocumentText,
  DocumentTile,
  DocumentTiles,
  MTResponse, Tile,
  Language
} from '../model/alef_model';
import { LanguageDocument } from '../model/languageDocument'
import { getLogger } from '../config/LogConfig';
import { DynamicConfig } from '../config/app_config';

export class DocumentService {
  private readonly log = getLogger('services.document_service');
  
  constructor(readonly config: DynamicConfig) {
  }
  
  public documents(success: (docs: Document[]) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting documents.`);
    doGet(`${this.config.apiUrl}/documents`,
      (doc_dat) => success(doc_dat as Document[]),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentText(documentId: number, success: (doc: DocumentText) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting document text.`);
    doGet(`${this.config.apiUrl}/documents/${documentId}/text`,
      (doc_dat) => success(doc_dat as DocumentText),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentSegmentsTiles(documentId: number, success: (doc: DocumentSegmentTiles) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting document segments and tile information.`);
    doGet(`${this.config.apiUrl}/documents/${documentId}/text-seg-tiles`,
      (doc_dat) => success(doc_dat as DocumentSegmentTiles),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentSegments(documentId: number, success: (doc: DocumentSegments) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting document segments.`);
    doGet(`${this.config.apiUrl}/documents/${documentId}/segments`,
      (doc_dat) => success(doc_dat as DocumentSegments),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public dictionaryLookup(searchTxt: string, success: (searchResults: string[]) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting words.`);
    doPost(`${this.config.apiUrl}/dictionary/search`,
      { 'search_str': searchTxt },
      (search_results) => success(search_results as string[]),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentReaderOneCol(documentId: number, success: (doc: DocumentTiles) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting document text one col.`);
    doGet(`${this.config.apiUrl}/documents/${documentId}/reader-1col`,
      (doc_dat) => success(doc_dat as DocumentTiles),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentAdd(document: LanguageDocument, success: (msg: string, docId: number) => void, failure: (msg: string) => void): void {
    this.log.info('Adding document to database.');
    let doc = {
      ...document,
      timestamp: Date.now(),
      language: document.language?.label ?? '',
      docType: document.docType?.label ?? ''
    }
    doPost(`${this.config.apiUrl}/documents/add`, { 'doc': doc },
      (result: { msg: string, doc_id: number }) => success(result.msg, result.doc_id),
      (msg: string) => failure(msg))
    this.log.info('Done with fetch!')
  }
  
  public documentUpdate(document: Document, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info('Updating document in database')
    doPost(`${this.config.apiUrl}/documents/update`, { 'doc': document },
      (msg: string) => success(msg),
      (msg: string) => failure(msg)
    )
    this.log.info('Done with fetch')
  }
  
  public documentsByUser(success: (docs: Document[]) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting documents by user_id.`);
    doGet(`${this.config.apiUrl}/documents/user`,
      (doc_dat) => success(doc_dat as Document[]),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentsByShared(success: (docs: Document[]) => void, failure: (msg: string) => void): void {
    this.log.info(`Getting documents by user_id.`);
    doGet(`${this.config.apiUrl}/documents/shared`,
      (doc_dat) => success(doc_dat as Document[]),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentShare(docId: number, userToShare: string, success: (msg: string) => void, failure: (msg: string) => void): void {
    this.log.info(`Sharing docId: ${docId} with user: ${userToShare}.`);
    let data = {
      'docId': docId,
      'userToShare': userToShare
    }
    doPost(`${this.config.apiUrl}/documents/share`,
      { 'data': data },
      (msg: string) => success(msg),
      (msg: string) => failure(msg));
    this.log.info('Done with fetch!');
  }
  
  public documentTiles(docId: number, success: (docTile: DocumentTile) => void, failure: (msg: string) => void): void {
    doGet(`${this.config.apiUrl}/documents/${docId}/tiles`,
      (docTile: DocumentTile) => success(docTile),
      (msg: string) => failure(msg))
  }
  
  public callGoogleTranslate(segmentId: number, success: (translationResp: MTResponse) => void, failure: (msg: string) => void): void {
    doGet(`${this.config.apiUrl}/mt/google/${segmentId}`,
      (translationResp: MTResponse) => success(translationResp),
      (msg: string) => failure(msg))
  }
  
  public translateSegments(segments: string[], language: Language, success: (result: { translated_segments: string[] }) => void,
                           failure: (msg: string) => void): void {
    doPost(`${this.config.apiUrl}/mt/google/text`,
      { 'segments': segments, 'language': language },
      (result: { translated_segments: string[] }) => {
        success(result)
      },
      (msg: string) => failure(msg)
    )
  }
  
  public saveUserTiles(docId: number, editedTiles: Tile[],
                       success: (result: { success: string; updatedTiles: Tile[] }) => void,
                       failure: (msg: string) => void): void {
    doPost(`${this.config.apiUrl}/documents/save-user-tiles`,
      { 'doc_id': docId, 'edited_tiles': editedTiles },
      (result: { success: string, updatedTiles: Tile[] }) => {
        success(result)
      },
      (msg: string) => failure(msg));
  }
  
  public deleteDocument(docId: number, success: (result: string) => void,
                        failure: (result: string) => void) {
    this.log.info(`Calling delete document api endpoint with doc id: ${docId}!`)
    doPost(`${this.config.apiUrl}/documents/delete`, { 'doc_id': docId },
      (result: { success: string }) => success(result.success),
      (result: string) => failure(result)
    )
    this.log.info('Done with post!')
  }
}
