/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Warning Modal used to warn the user when they have performed an operation that is not allowed.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */


import { Modal, Typography } from '@mui/material';
import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


type WarningModalProps = {
  isOpen: boolean
  handleClose: () => void
  modalHeader: string
  warningMessage: string
  displayYesNo?: boolean
  onYesClick?: () => void
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 10,
  p: 4,
};

export const WarningModal = ({
                               isOpen,
                               handleClose,
                               modalHeader,
                               warningMessage,
                               displayYesNo = false,
                               onYesClick = () => {
                               }
                             }: WarningModalProps) => {
  
  
  return (
    <Modal
      open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" sx={{ backgroundColor: 'rgba(0,0,0,.5)' }}
      onClick={displayYesNo ? undefined : handleClose}>
      <Paper sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalHeader}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {warningMessage}
        </Typography>
        {displayYesNo &&
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, mt: '1em' }}>
            <Button variant="contained" sx={{ flex: 1, p: '.75em', marginRight: '1em' }} onClick={() => {
              onYesClick()
              handleClose()
            }}>{'Yes'}</Button>
            <Button variant="contained" sx={{ flex: 1, p: '.75em', marginLeft: '1em' }} onClick={handleClose}>{'No'}</Button>
          </Box>}
      </Paper>
    </Modal>
  )
}