/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The Document Reader Component (Inline Reader) is single column displayed document with a slider for adjusting the displayed language.
 * Increments of the slider between the min/max will display both the tl and en language of the document.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { useTransition } from 'react'
import { Typography } from '@mui/material';
import { getLogger } from '../config/LogConfig';
import { DocumentService } from '../services/document_service';
import Waiting from './Waiting';
import {
  Language, DocumentTile, Tile, Document, Token
} from '../model/alef_model';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { useConfig } from '../providers/Config';
import { useNavigate } from 'react-router-dom';
import { WarningModal } from './modals/WarningModal';
import { tilesToString } from '../core/text';
import { styled } from '@mui/system';

type SegIdMap = { [key: number]: Tile[] }

const TileSpan = styled('span')`
  background-color: white;
  padding: 5px;
  border: solid 1px white;

  :hover {
    border: solid 1px lightblue;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const DocumentReaderCmp = (props: { documentId: number }) => {
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const log = getLogger('components.DocumentReaderCmp');
  /** The level where the order is English, and the Target Language is hidden. */
  const ENG_ONLY_LVL = 4;
  /** The level where the order is Target Language, and the English is hidden. */
  const TL_ONLY_LVL = 1
  /** The break point level where this level and below are ordered by the Target Language, and any level above is ordered by the English. */
  const MAX_TL_ORD_LVL = 2
  const [isPending, startTransition] = useTransition() // for making slider ui higher priority.
  const [document, setDocument] = React.useState<Document | null>(null)
  const [tiles, setTiles] = React.useState<Tile[] | null>(null);
  const [visibleLevel, setVisibleLevel] = React.useState<number>(2);
  const [level, setLevel] = React.useState<number>(2);
  const [languageObj, setLanguageObj] = React.useState <Language | null>(null)
  // We should not store JSX in state, need a way to keep useTransition working with slider
  const [renderedTileGroups, setRenderedTileGroups] = React.useState<JSX.Element[]>([])
  const hasDoneLookup = React.useRef(false)
  const [warningModalOpen, setWarningModalOpen] = React.useState<boolean>(false)
  let navigate = useNavigate();
  
  React.useEffect(() => {
    const do_lookup = async () => {
      log.info('Do lookup');
      hasDoneLookup.current = true
      await doc_service.documentTiles(props.documentId, (docTile: DocumentTile) => {
          setDocument(docTile.document)
          setTiles(docTile.tiles)
          const langObj = docTile.document.language;
          setLanguageObj(langObj ? langObj : null)
        },
        (msg: string) => {
          log.error(`Got an error on text lookup: ${msg}`)
          // Open Modal if user should not have permission
          // TODO: ONLY OPEN MODAL IF REASON IS ACCESS DENIED
          setWarningModalOpen(true)
        })
    }
    if (!tiles && !hasDoneLookup.current) {
      do_lookup();
    } else {
      renderTileGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiles, level]);
  
  const isRtl = () => {
    if (!languageObj) return false
    return languageObj.rtl
  }
  
  function makeTopText(t: Tile) {
    let token: Token = level < 3 ? t.tl_token : t.e_token
    let txt: string = token ? token.term : '◇'
    let space: string = '\u00A0';
    const topIsRTL = level <= MAX_TL_ORD_LVL && isRtl();
    const dirVal = topIsRTL ? 'rtl' : 'ltr'
    const textAlign: string = txt === '◇' ? 'center' : (topIsRTL ? 'right' : 'left');
    return <Typography sx={{ direction: dirVal, textAlign: textAlign, display: 'table-row' }}
                       component="span">  {space + txt}  </Typography>
  }
  
  function makeBottomText(t: Tile) {
    let token: Token
    if (level === 2) {
      token = t.e_token
    } else if (level === 3) {
      token = t.tl_token
    } else {
      return null
    }
    const txt: string = token ? token.term : '◇'
    const space: string = '\u00A0';
    const bottomIsRTL = isRtl() && level > MAX_TL_ORD_LVL;
    const dirVal = bottomIsRTL ? 'rtl' : 'ltr'
    const textAlign: string = txt === '◇' ? 'center' : (bottomIsRTL ? 'right' : 'left');
    return <Typography sx={{ direction: dirVal, textAlign: textAlign, display: 'table-row', color: '#bbb' }}
                       component="span"> {space + txt}  </Typography>
  }
  
  function makeTile(t: Tile): React.JSX.Element {
    return (
      <TileSpan key={`tile-${t.id}`}>
        {makeTopText(t)}
        {makeBottomText(t)}
      </TileSpan>
    );
  }
  
  /** Create tile elements including segment number for all the tiles in a segment. This assumes tiles provided are already sorted. */
  const makeTilesForSegment = (sortedTiles: Tile[], index: number): React.JSX.Element => {
    // Add the wrapping around the segment. Paragraph number and div that contains the tile.
    // When showing RTL language align/set direction to rtl so text is right aligned.
    return (
      <div key={`group-${index}`} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'baseline' }}>
        {level > MAX_TL_ORD_LVL || !isRtl() ? (
          <p style={{ paddingLeft: '0.5em', paddingRight: '0.5em', fontWeight: 'bold' }}>{index + 1}.</p>) : ''}
        <div style={{
          direction: level <= 2 && isRtl() ? 'rtl' : 'ltr', display: 'flex',
          flexWrap: 'wrap', flexDirection: 'row', width: '100%'
        }}>
          {makeDivForSegment(sortedTiles)}
        </div>
        {level <= MAX_TL_ORD_LVL && isRtl() ? (
          <p style={{ direction: 'rtl', paddingLeft: '0.5em', paddingRight: '0.5em', fontWeight: 'bold' }}>{index + 1}.</p>) : ''}
      </div>
    );
  }
  
  /** Create tile elements for all the tiles in a segment, either as a single div with the segment text, or as a set of tile divs.  */
  const makeDivForSegment = (sortedTiles: Tile[]): React.JSX.Element | React.JSX.Element[] => {
    // Do not show the tiles divs when we are dealing with only the translation or English
    if (level === TL_ONLY_LVL || level === ENG_ONLY_LVL) {
      const dirVal = level <= MAX_TL_ORD_LVL && isRtl() ? 'rtl' : 'ltr'
      return (
        <Typography sx={{ direction: dirVal }} component="span">
          {tilesToString(sortedTiles, true, level === TL_ONLY_LVL)}
        </Typography>
      );
    }
    return (sortedTiles.map(makeTile));
  }
  
  /**
   * Determine the sort function, Target Language, or English, depending on the current selected level.
   * @Return Returns a function to be used for sorting a list of tiles.
   */
  const getSortFn: () => (a: Tile, b: Tile) => number = () => {
    return level > MAX_TL_ORD_LVL ? (a: Tile, b: Tile) => a.en_loc - b.en_loc : (a: Tile, b: Tile) => a.tl_loc - b.tl_loc;
  }
  
  const renderTileGroups = () => {
    startTransition(() => {
      if (!tiles) return
      // Get map of tiles per segment
      const segIdMap: SegIdMap = tiles.reduce((acc: SegIdMap, tile: Tile) => {
        if (!acc[tile.seg_id]) {
          acc[tile.seg_id] = []
        }
        acc[tile.seg_id].push(tile)
        return acc
      }, {} as SegIdMap)
      // Convert map to array of arrays
      const groupedTiles = Object.values(segIdMap)
      
      const renderedTileGroups: React.JSX.Element[] = groupedTiles.map((group, index) =>
        // Sort the tiles here before creating the UI components.
        makeTilesForSegment(group.sort(getSortFn()), index)
      );
      setRenderedTileGroups(renderedTileGroups)
    })
  }
  
  /**
   * Handles the event of dragging the slider bar (only ui)
   * @param event the slider bar event captured
   * @param newValue the new value that the slider bar is positioned at
   */
  const handleSliderDrag = async (event: Event, newValue: number | number[]) => {
    setVisibleLevel(newValue as number)
  };
  
  /**
   * Handles the event of dropping the slider bar (committing the change in position)
   * @param event the event of dropping the slider bar
   * @param newValue the position where the slider bar was dropped
   */
  const handleSliderRelease = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    newValue = Math.round(newValue as number)
    setVisibleLevel(newValue)
    setLevel(newValue)
  };
  
  function valueLabelFormat(value: number) {
    const tl = languageObj ? languageObj.name : 'Target Language'
    if (value < 1.5) {
      return tl + ' only';
    } else if (value < 2.5) {
      return tl + ' ordered';
    } else if (value < 3.5) {
      return 'English ordered';
    } else if (value < 4.5) {
      return 'English Only';
    }
    return ''
  }
  
  const handleModalClose = () => {
    setWarningModalOpen(false)
    navigate('/documents')
  }
  
  if (warningModalOpen) {
    return <WarningModal isOpen={warningModalOpen} handleClose={handleModalClose}
                         modalHeader={'Operation Not Permitted'}
                         warningMessage={'This document does not exist or you do not have access privileges.'}/>
  }
  
  if (!tiles || !document) {
    return <Waiting/>
  }
  
  return (
    <>
      <Container>
        <Grid container spacing={2} sx={{ pt: 6 }}>
          <Grid xs={8} md={6}>
            <Typography variant="h6" sx={{ pt: 0, pb: 0, textAlign: 'center' }}>"{document.title}"</Typography>
            <Typography variant="h6" sx={{ pt: 1, pb: 2, textAlign: 'center' }}>by {document.author}</Typography>
          </Grid>
          <Grid xs={4} md={6} display="flex" alignItems="center" justifyContent="center">
            <Stack spacing={2} sx={{ px: 2, py: 3, border: 1, borderColor: '#ddd', borderRadius: 2 }} direction="row" alignItems="center">
              <Typography sx={{}} id="content-type-slider" gutterBottom>{languageObj ? languageObj.name : 'Target Language'
              }</Typography>
              <Slider value={visibleLevel} sx={{ minWidth: 100, width: '15vw' }} step={0.01}
                      min={1}
                      max={4}
                      marks={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]}
                      valueLabelFormat={valueLabelFormat}
                      onChange={handleSliderDrag}
                      onChangeCommitted={handleSliderRelease}
                      aria-labelledby="content-type-slider"
                      aria-label="Display Content" valueLabelDisplay="auto"/>
              <Typography sx={{}} id="content-type-slider" gutterBottom>English</Typography>
            </Stack>
          </Grid>
        </Grid>
        {isPending ? <Waiting/> :
          <Grid container rowSpacing={1} columnSpacing={2}
                role="sortable-game-card"
                direction={isRtl() ? 'row-reverse' : 'row'}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: isRtl() ? 'end' : 'flex-start',
                  alignItems: 'flex-start',
                }}>
            {renderedTileGroups}
          </Grid>}
      </Container>
    </>
  );
};

export default DocumentReaderCmp
