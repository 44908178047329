/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Link Renderer used in the document table.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

// Adapted from https://github.com/mui/mui-x/blob/v5.17.24/packages/grid/x-data-grid-generator/src/renderer/renderLink.tsx
interface GridLinkProps {
  location: string;
  children: string;
  tabIndex: number;
}

const StyledLink = styled(Link)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: 'inherit',
});

export const GridLink = React.memo(function GridLink(props: GridLinkProps) {
  // TODO we could add a click handler here if we need special behaviors
  // We would also need to add onClick={handleClick}  to Link below.
  //const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
  //  event.preventDefault();
  //  event.stopPropagation();
  //};
  // To use an external href use:
  //  href={props.href}>
  return (
    <StyledLink tabIndex={props.tabIndex} to={props.location}>
      {props.children}
    </StyledLink>
  );
});

export function renderLink(linkPrefix: string, linkSuffix: string, params: GridRenderCellParams<string, any, any>) {
  if (params.rowNode.isAutoGenerated || !params.value) {
    return '';
  }
  
  return (
    <GridLink location={linkPrefix + params.rowNode.id + linkSuffix} tabIndex={params.tabIndex}>
      {params.value}
    </GridLink>
  );
}
