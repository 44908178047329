/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * CardSorter View. A view displayed within the HyperGlossCmp that displays tiles (SortableGameCards) and allows the user
 * to rearrange them via drag and drop. Tiles may be edited and edits may be saved to ALEF.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React, {forwardRef, useRef} from 'react';
import Grid from '@mui/material/Grid';
import {SortableGameCard} from '../../components/game/SortableGameCard';
import {Language, Tile} from '../../model/alef_model';
import Waiting from '../../components/Waiting';
import Container from '@mui/material/Container';
import {getLogger} from '../../config/LogConfig';
import {DeepPartial, TileDict} from '../../model/cardsorter_model';

type CardSorterProps = {
  sentence?: string
  segId: number
  tl_lang: Language
  tilesDict: TileDict
  setTilesDict: React.Dispatch<React.SetStateAction<TileDict>>
  tileIds: number[]
  updateTile: (tileId: number, updatedProperties: DeepPartial<Tile>) => void
  updateTileIds: (updates: { segId: number, updatedTileIds: number[] }[], saving?: boolean) => void
  originalTilesRef: React.MutableRefObject<TileDict>
  handleMoveTile: (targetSegId: number, dragIndex: number, hoverIndex: number, insertBefore: boolean) => void
  isUIControlDisabled: boolean,
  tileOrder: string
}

/** A Ref for the card sorter component that exposes limited methods to parent components. */
type CardSorterRef = {
  handleAddNewTile: () => void
}

const CardSorter = forwardRef<CardSorterRef, CardSorterProps>(({ segId, tl_lang,
                                                 tilesDict, setTilesDict, tileIds,
                                                 updateTile, updateTileIds, originalTilesRef,
                                                 handleMoveTile, isUIControlDisabled, tileOrder}, ref) => {
  const log = getLogger('views.main.CardSorter');
  const inputRefs: React.MutableRefObject<Array<HTMLInputElement | null>> = useRef<Array<HTMLInputElement | null>>([])

  // RHB 2024.04.03: TODO Re-enable support for autocomplete. See #463.
  /** Whether or not autocomplete is turned on. */
  const [checked] = React.useState<boolean>(false)


  /**
   * Customize the ref interface to only expose desired methods.
   */
  React.useImperativeHandle(ref, () => {
    return { handleAddNewTile: () => handleAddNewTile() }
  });

  /**
   * After render focus the first tile input
   */
  React.useEffect(() => {
    inputRefs.current[0]?.focus()
  }, [inputRefs, segId])

  /**
   * Inserts a new tile into both the tilesDict and tileIds array and updates all the subsequent Tile en_locs, and tl_loc.
   * @param tile the Tile adjacent to the newly inserted tile
   * @param right boolean for if the new Tile is to be inserted to the right
   */
  const handleInsertNewTile = (right: boolean = true, tile: Tile | null = null) => {
    if (!tilesDict) {
      return
    }
    if (tl_lang.rtl && tileOrder === 'tl') {
      right = !right
    }
    log.info(`Inserting a new tile to the ${right ? 'right' : 'left'} of tile:`, tile)
    // Make a new tile to insert
    // Find the next negative id from all tiles across all segments
    const negativeIds: number[] = Object.keys(tilesDict).map(id => parseInt(id, 10)).filter(id => id < 0)
    const nextNegativeId: number = negativeIds.length > 0 ? Math.min(...negativeIds) - 1 : -1
    const newTile: Tile = {
      id: nextNegativeId,
      seg_id: segId,
      e_id: -1,
      tl_id: -1,
      active: true,
      tl_loc: !tile ? 0 : right ? tile.tl_loc + 1 : tile.tl_loc,
      en_loc: !tile ? 0 : right ? tile.en_loc + 1 : tile.en_loc,
      i_delta: 0,
      e2tl_index: 0,
      tl2e_index: 0,
      n_ins: 0,
      locked: false,
      e_token: {
        id: -1,
        term: '',
        phonetic: '',
        transliteration: '',
        lang_id: -1,
        frequency: -1,
        source: '',
        created_user: -1,
        is_punct: false,
      },
      tl_token: {
        id: -1,
        term: '◇',
        phonetic: '',
        transliteration: '',
        lang_id: -1,
        frequency: -1,
        source: '',
        created_user: -1,
        is_punct: false,
      },
      modified: true
    }
    // loop through tiles and up the index of each
    const workingSetOfTiles: TileDict = { ...tilesDict }
    const sortedTileIds: number[] = [...tileIds]
    sortedTileIds.sort((a, b) => workingSetOfTiles[a]?.tl_loc - workingSetOfTiles[b]?.tl_loc)
      .forEach(tileId => {
        if (workingSetOfTiles?.[tileId].tl_loc >= newTile.tl_loc) {
          log.info('Changing tl_loc ' + workingSetOfTiles?.[tileId].tl_loc)
          workingSetOfTiles[tileId].tl_loc += 1
        }
      })
    tileIds.forEach(tileId => {
      if (workingSetOfTiles?.[tileId].en_loc >= newTile.en_loc) {
        workingSetOfTiles[tileId].en_loc += 1
      }
    })
    // Add the new tile to the dictionary
    workingSetOfTiles[newTile.id] = newTile
    setTilesDict(workingSetOfTiles)
    // Need to update ref object with new tile as well
    originalTilesRef.current[newTile.id] = newTile
    // Splice new tile id into list of tileIds
    const insertPosition: number = !tile ? 0 : right ? tile.en_loc + 1 : tile.en_loc - 1
    const updatedTileIds: number[] = [
      ...tileIds.slice(0, insertPosition),
      newTile.id,
      ...tileIds.slice(insertPosition)
    ]
    updateTileIds([{ segId, updatedTileIds }])
  }
  
  /**
   * Removes a tile from the tilesDict state and the position saved in the tileIds array
   * @param tileToRemove the tile to be removed
   */
  const handleDeleteTile = (tileToRemove: Tile): void => {
    if (!tilesDict) {
      return
    }
    
    const updatedArray: number[] = [...tileIds].filter((id: number) => id !== tileToRemove.id)
    updateTileIds([{ segId: segId, updatedTileIds: updatedArray }])
    
    const tileId: number = tileToRemove.id
    const updatedTiles: TileDict = { ...tilesDict }
    // loop through tiles and dec the index of each
    const sortedTileIds: number[] = [...tileIds]
    sortedTileIds.sort((a: number, b: number) => updatedTiles[a].tl_loc - updatedTiles[b].tl_loc)
      .forEach((tileId: number) => {
        if (updatedTiles?.[tileId].tl_loc >= tileToRemove.tl_loc) {
          log.info('Changing tl_loc ' + updatedTiles?.[tileId].tl_loc)
          updatedTiles[tileId].tl_loc -= 1
        }
      })
    tileIds.forEach((tileId: number) => {
      if (updatedTiles?.[tileId].en_loc >= tileToRemove.en_loc) {
        updatedTiles[tileId].en_loc -= 1
      }
    })
    delete updatedTiles[tileId]
    setTilesDict(updatedTiles)
    // Need to update ref object as well
    originalTilesRef.current = updatedTiles
  }
  
  /**
   * Edits the tl term of a tile
   * @param tile the tile to edit
   * @param newTLTerm the new TL term being set
   */
  const handleEditTL = (tile: Tile, newTLTerm: string) => {
    if (!tilesDict) return
    updateTile(tile.id, { tl_token: { term: newTLTerm } })
  }

  /**
   * Inserts a new tile to the right of the last tile
   */
  const handleAddNewTile = () => {
    if (!tilesDict) return
    if (tileIds.length > 0) {
      // Get the last tile in the current segment
      const lastTileId: number = tileIds[tileIds.length - 1]
      const lastTile: Tile = tilesDict[lastTileId]
      // Use Insert New Tile method to insert to the right of last tile in tileIds list for current segId
      handleInsertNewTile(true, lastTile)
    } else {
      handleInsertNewTile()
    }
  }
  
  /**
   * Renders a single SortableGameCard with the tile provided and an index from the map of tilesDict (en_loc).
   *
   * @param key The index of the tile (en_loc)
   * @param tile The tile that has the information for the tile to be rendered
   */
  const renderCard = (key: number, tile: Tile) => {
    return (
      <SortableGameCard
        key={key}
        tile={tile}
        updateTile={updateTile}
        moveTile={(dragIndex, hoverIndex, insertBefore) => handleMoveTile(segId, dragIndex, hoverIndex, insertBefore)}
        inputRefs={inputRefs}
        checked={checked}
        handleInsertNewTile={handleInsertNewTile}
        handleDeleteTile={handleDeleteTile}
        handleEditTL={handleEditTL}
        tileOrder={tileOrder}
        isUIControlDisabled={isUIControlDisabled}
        tl_lang={tl_lang}
        tileIds={tileIds}
      />
    )
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={2}
          data-testid="game-card-container"
          direction={(tileOrder === "tl" && tl_lang.rtl) ? 'row-reverse' : 'row'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: (tileOrder === "tl" && tl_lang.rtl) ? 'end' : 'flex-start',
            alignContent: 'start',
            marginTop: 0,
            marginInline: 0,
            paddingInline: '1em',
            height: '100%'
          }}>
        {Object.keys(tilesDict).length === 0 ? (
        <Container sx={{ width: '100%', justifyContent: 'start', marginTop: '-100px' }}><Waiting/></Container>
      ) : (
        // Map the array of tileIds, rendering a card using the tilesDict[id]
        tileOrder === 'tl' ? (
            // To display tiles in TL order we need to map to the tl_loc of the tiles sorting them by tl_loc
            tileIds?.map((tileId) => tilesDict[tileId])
              .sort((a, b) => a.tl_loc - b.tl_loc)
              .map((tile) => renderCard(tile.id, tile))
          ) :
          (
            // This renders in order of the tileIds array which is in en_loc or english order by default
            tileIds?.map((tileId, index) => renderCard(tileId, tilesDict[tileId]))
          )
      )}
    </Grid>
  );
});

export type { CardSorterRef };
export default CardSorter;
