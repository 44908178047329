/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Context Menu used to for right-clicking objects in ALEF.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Document } from '../../model/alef_model';
import { WarningModal } from '../modals/WarningModal';

type RowMenuProps = {
  doc: Document
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  onDelete: (id: number) => void
  isAdmin: boolean
  isOwner: boolean
  makePublic: (docId: number) => void
}

export const ContextMenu: React.FC<RowMenuProps> = ({
                                                      doc, anchorEl, setAnchorEl,
                                                      onDelete, isAdmin, isOwner, makePublic
                                                    }) => {
  const [warningModalOpen, setWarningModalOpen] = React.useState<boolean>(false)
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const handleDeleteClick = () => {
    if (isModifiable()) {
      if (doc.public) {
        setWarningModalOpen(true)
      } else {
        onDelete(doc.id)
        handleClose()
      }
    }
  }
  
  const handleYesClick = () => {
    onDelete(doc.id)
    handleClose()
  }
  
  const handleModalClose = () => {
    setWarningModalOpen(false)
  }
  
  /**
   * Handles the making public or private click event of the context menu then closes the context menu.
   */
  const handleMakePublic = () => {
    if (isModifiable()) {
      makePublic(doc.id)
      handleClose()
    }
  }
  
  /**
   * Check if current user is the owner of the document or an admin.
   */
  const isModifiable = (): boolean => {
    if (isOwner || isAdmin) return true
    return false
  }
  
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem sx={{
        color: isModifiable() ? 'black' : 'gray', paddingInline: '2em',
        cursor: isModifiable() ? 'pointer' : 'not-allowed'
      }} onClick={handleDeleteClick}>Delete</MenuItem>
      <MenuItem sx={{
        paddingInline: '2em',
        color: isModifiable() ? 'black' : 'gray',
        cursor: isModifiable() ? 'pointer' : 'not-allowed'
      }} onClick={handleMakePublic}>{doc.public ? 'Make Private' : 'Make Public'}</MenuItem>
      {warningModalOpen &&
        <WarningModal
          isOpen={warningModalOpen} handleClose={handleModalClose}
          modalHeader={'Public Document'}
          warningMessage={'This document is public. This action will possibly remove saved work by others. Do you wish to continue?'}
          displayYesNo={true}
          onYesClick={handleYesClick}
        />}
    </Menu>
  )
}