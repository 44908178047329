/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Cheat Dropdown component used in the hypergloss editor/card sorter views.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { DocumentService } from '../../services/document_service';
import debounce from 'lodash/debounce'
import { Tile } from '../../model/alef_model';
import { DeepPartial } from '../../model/cardsorter_model';
import { getLogger } from '../../config/LogConfig';
import { useConfig } from '../../providers/Config';

interface Option {
  id: number
  label: string
}

interface dictionaryRequest {
  input: string
}

type CheatDropdownProps = {
  tile: Tile,
  inputRefs: React.MutableRefObject<Array<HTMLInputElement | null>>,
  checked: boolean,
  handleTileUpdate: (updatedProperties: DeepPartial<Tile>) => void,
  textFieldValue: string,
  tileOrder: string,
  isUIControlDisabled: boolean
}

type FetchDictionaryArgs = [
  dictionaryRequest,
  (results: string[]) => void,
  (errorMsg: string) => void
]

const CheatDropdown = ({ tile, inputRefs, handleTileUpdate, textFieldValue, checked, tileOrder, isUIControlDisabled}: CheatDropdownProps) => {
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const log = getLogger('views.component.game.CheatDropdown');
  const [options, setOptions] = useState<Option[]>([])
  const [localText, setLocalText] = useState<string>(textFieldValue)
  const containerWidth = Math.max(tile.tl_token?.term.length, localText.length) + 1
  
  const debouncedFetch = React.useMemo(
    () => {
      const fetchDictionaryOptions = (request: dictionaryRequest, successfulCallback: (results: string[]) => void,
                                      errorCallback: (errorMsg: string) => void) => {
        const trimmedRequest: string = request.input.trim()
        if (trimmedRequest !== '' && trimmedRequest !== '?') {
          doc_service.dictionaryLookup(trimmedRequest, successfulCallback, errorCallback)
        }
      }
      return debounce((...args: FetchDictionaryArgs) => {
        // if (card.lock) return //TODO: NEED WAY TO IGNORE DICTIONARY LOOKUP AFTER LOCK
        fetchDictionaryOptions(...args)
      }, 1000)
    }, [doc_service])
  
  const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    // Catch empty characters and store as diamonds
    setLocalText(value)
    handleTileUpdate({ e_token: { term: value } })
    if (reason === 'reset') {
      move(false);
      return;
    }
    if (!checked) return
    if (reason !== 'input') {
      setOptions([])
      return;
    }
    if (value === '') return;
    
    let newOptions: Option[] = [];
    debouncedFetch({ input: value }, (results?: readonly string[]) => {
        if (results) {
          // Makes unique keys for list options to prevent warning
          newOptions = results.map((item, index) => ({
            id: index + 1,
            label: item,
          }))
        }
        setOptions(newOptions)
      },
      (errorMsg: string) => {
        log.error(`Got an error on dictionary lookup: ${errorMsg}`)
      })
  }
  
  
  const handleKeyPressDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.shiftKey && event.key === ' ') return;
    if ((event.shiftKey && event.key === 'Tab') || (event.shiftKey && event.key === ' ')) {
      event.preventDefault()
      move(true);
    }
    // TODO: FIX ENTER KEY ON DROPDOWN OPEN
    else if (event.key === 'Tab' || event.key === ' ' || event.key === 'Enter') {
      event.preventDefault()
      move(false);
    }
  }
  
  
  const handleOptionSelection = (selectedOption: string) => {
    setLocalText(selectedOption)
    handleTileUpdate({ locked: false, e_token: { term: selectedOption } })
  }
  
  const handleLockTile = () => {
    if (config.lockTiles) {
      if (localText === '') {
        handleTileUpdate({locked: true, e_token: {term: '◇'}})
      } else {
        handleTileUpdate({locked: true})
      }
    }
  }

  const move = (isBackMove: boolean) => {
    if (isBackMove) {
      //log.info("Moving back");
      moveBack();
    } else {
      //log.info("Moving forward");
      moveForward();
    }
  }

  /**
   * Using the inputRefs provided by the CardSorter, lock the current input and progress to the next input. If
   * on the last input lock and return.
   */
  const moveForward = () => {
    let inputLen = inputRefs.current.length - 1;
    let nextIdx = tileOrder === 'tl' ? tile.tl_loc : tile.en_loc;
    nextIdx++;
    //log.info(`Starting at ${nextIdx-1} moving to ${nextIdx}. Input Len=${inputLen}`);
    let nextTile = inputRefs.current[nextIdx]
    // check if at last tile if so, lock and break
    if (nextIdx > inputLen) {
      //log.info(`return early`);
      handleLockTile()
      return;
    }
    // Loop forwards through the tiles until you get to the next unlocked tile.
    while (nextIdx <= inputLen && (nextTile === null || !(nextTile?.ariaLabel?.startsWith('Input box')))) {
      nextTile = inputRefs.current[nextIdx++]
    }
    // focus on next text field
    nextTile?.focus()
    // lock the text field
    handleLockTile()
  }
  
  /**
   * Using the inputRefs provided by the CardSorter, progress to the prev input. Do not lock or set tile as modified.
   */
  const moveBack = () => {
    // Find the nearest previous unlocked tile/input
    let prevIdx = tileOrder === 'tl' ? tile.tl_loc : tile.en_loc;
    //log.info(`Starting at ${prevIdx}.`);
    // check if at first tile if so, lock and break
    if (prevIdx === 0) {
      //log.info(`Return early for zero`);
      handleLockTile()
      return;
    }
    let prevTile: HTMLInputElement | null = inputRefs.current[--prevIdx]

    // Loop backwards through the tiles until you get to the next unlocked tile.
    while (prevIdx >= 0  && (prevTile === null || !(prevTile?.ariaLabel?.startsWith('Input box')))) {
      //log.info(`Entering while with ${prevIdx}`);
      prevTile = inputRefs.current[prevIdx--];
    }
    //log.info(`Done! Focusing tile at ${prevIdx+1} is ${prevTile?.id}`);
    // focus on prev text field
    prevTile?.focus()
    // Lock the text field
    handleLockTile()
  }
  
  const renderInput = (params: JSX.IntrinsicAttributes & TextFieldProps) => (
    <TextField
      {...params}
      sx={{
        '& .MuiInputBase-input': {
          padding: '2px 4px 2px 6px!important'
        }
      }}
      disabled={isUIControlDisabled}
      autoFocus
      onKeyDown={(e) => {
        handleKeyPressDown(e)
      }}
      inputProps={{
        ...params.inputProps,
        'aria-label': `Input box ${tileOrder === 'tl' ? tile.tl_loc : tile.en_loc}`,
        'data-testid': `input-${tileOrder === 'tl' ? tile.tl_loc : tile.en_loc}`
      }}
      inputRef={(ref) => {
        inputRefs.current[tileOrder === 'tl' ? tile.tl_loc : tile.en_loc] = ref
      }}
    />
  )
  
  const renderOption = (params: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement>
    & React.LiHTMLAttributes<HTMLLIElement>, option: Option,) => (
    <li
      {...params} key={option.id} style={{ overflow: 'hidden' }} onClick={() => {
      handleOptionSelection(option.label)
    }}
    >
      <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'nowrap' }}>
        {option.label}
      </Typography>
    </li>
  )
  
  const customFilterOptions = (options: Option[], { inputValue }: { inputValue: string }) => {
    if (inputValue.trim() === '') return []
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }
  
  return (
    <Autocomplete
      sx={{
        width: `${containerWidth + 1}ch`,
        '& .MuiOutlinedInput-root': {
          padding: '0px!important',
        },
      }}
      PaperComponent={({ children }) => (
        <Paper style={{ width: 'fit-content' }}>{children}</Paper>
      )}
      disabled={isUIControlDisabled}
      filterOptions={customFilterOptions}
      options={checked ? options : []}
      disableClearable
      autoComplete
      includeInputInList
      filterSelectedOptions
      freeSolo
      onInputChange={handleInputChange}
      inputValue={textFieldValue === '◇' ? '' : textFieldValue} // Set to the initial text field value so we drop changes on a refresh action.
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default CheatDropdown
