/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * A wrapper for requiring auth to view a page of ALEF.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { useAuth } from '../providers/Auth';
import {
  useLocation,
  Navigate
} from 'react-router-dom';
import { getLogger } from '../config/LogConfig';
import Waiting from './Waiting';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const log = getLogger('components.RequireAuth');
  
  const [loaded, setLoaded] = React.useState(false);
  
  // log.info("Auth check!");
  
  React.useEffect(() => {
    const asyncCallback = async () => {
      const cb = () => setLoaded(true);
      await auth.restore_session(cb, cb);
    }
    
    if (!auth.user) {
      asyncCallback()
    } else {
      setLoaded(true);
    }
  }, [auth]);
  
  if (!loaded) {
    // Need to restore the session
    log.info('Not loaded. Show wait!');
    return <Waiting/>
  } else if (auth.user) {
    // Already logged in and have a session.
    log.info(`Auth user found. Show children! my loc: ${JSON.stringify(location)}`);
    return children;
  } else {
    //log.info("No auth user found. Forward to login!");
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace/>;
  }
}

export default RequireAuth


// Approach ideas taken from:
// https://stackoverflow.com/questions/58444065/redirecting-using-useeffect-hook
/* const LoadingComponent = () => <div> Javier </div>
 *
 * function Landing(props) {
 *     const [state={notLoaded:true}, setState] = useState(null);
 *     useEffect(() => {
 *         const asyncCallback = async () =>{
 *             const data = await axios.get('/someApiUrl')
 *             setState(data)
 *         }
 *
 *         asyncCallback()
 *     },[]);
 *
 *     if(!state){
 *         return <FalseyComponent />
 *     }
 *     if(state.notLoaded){
 *         //return some loading component(s) (or nothing to avoid flicker)
 *         return <LoadingComponent /> // -or- return <div/>
 *     }
 *   return <TruthyComponent />
 * } */
