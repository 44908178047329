/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * A custom document grid for ALEF user profiles.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Document } from '../model/alef_model';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ShareDialog from './Profile/Content/ShareDialog';

interface DocumentGridProps {
  documents: Document[]
  shared?: boolean
}

const StyledLink = styled(Link)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: 'inherit',
  cursor: 'pointer'
});

const DocumentGrid2 = ({ documents, shared }: DocumentGridProps) => {
  const navigate = useNavigate()
  const [shareOpen, setShareOpen] = React.useState(false)
  
  
  const renderDocLink = (params: any) => {
    const location = '/documents/' + params.rowNode.id + '/reader'
    return (
      <StyledLink onClick={() => {
        navigate(location)
      }}>
        {params.value}
      </StyledLink>
    )
  }
  
  
  const renderShareButton = (params: any) => (
    <Container>
      <Button onClick={() => setShareOpen(true)}>
        Share
      </Button>
      <ShareDialog open={shareOpen} handleShareClose={() => setShareOpen(false)} docId={params.rowNode.id}/>
    </Container>
  )
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'language',
      headerName: 'Language',
      width: 200,
      valueFormatter: ({ value }) => value.name,
    },
    {
      field: 'title', headerName: 'Title', width: 150,
      renderCell: (params) => renderDocLink(params)
    },
    { field: 'description', headerName: 'Description', width: 200 },
  
  ];
  
  if (shared) {
    columns.push({
      field: 'share', headerName: 'Share', width: 200,
      renderCell: (params) => renderShareButton(params)
    })
  }
  
  return (
    <>
      <Box sx={{ height: 400 }}>
        <DataGrid rows={documents} columns={columns}/>
      </Box>
    </>
  );
};

export default DocumentGrid2
