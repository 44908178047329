/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Main View Layout contains the main content for ALEF including a app bar with the main views, avatar, and logout button.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import MenuItem from '@mui/material/MenuItem';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import LogoutIcon from '@mui/icons-material/Logout';
// import GroupWorkIcon from '@mui/icons-material/GroupWork';
// import GamesIcon from '@mui/icons-material/Games';
// import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
// import TranslateIcon from '@mui/icons-material/Translate';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/Auth';
import { styled } from '@mui/system';
// import AlefIcon from '../images/noun-aleph-670059-clean.png'
import Avatar from '@mui/material/Avatar';

const routes = [{
  label: 'Documents', icon: () => {
    return <CollectionsBookmarkIcon/>
  }, loc: '/documents'
},
  // {
  //   label: 'Grammar', icon: () => {
  //     return <TranslateIcon/>
  //   }, loc: '/grammar'
  // },
  // {
  //   label: 'Lexicon', icon: () => {
  //     return <LibraryBooksIcon/>
  //   }, loc: '/lexicon'
  // },
  // {
  //   label: 'Idafa', icon: () => {
  //     return <GroupWorkIcon/>
  //   }, loc: '/idafa'
  // },
  // {
  //   label: 'Card Sorter', icon: () => {
  //     return <GamesIcon/>
  //   }, loc: '/card-sorter'
  // }
];

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
`

// https://thenounproject.com/icon/aleph-670059/

const MainViewLayout = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const nav = useNavigate();
  const auth = useAuth();
  
  let location = useLocation();
  
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = (routeLoc: string) => {
    setAnchorElNav(null);
  };
  // <LanguageIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
  // sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
  
  function handleLogout() {
    auth.logout(() => {
      nav('/login')
    }, () => {
      nav('/login')
    })
  }
  
  function getUserInits() {
    if (auth.user && auth.user.first_name.length > 0 && auth.user.last_name.length > 0) {
      return (auth.user.first_name[0] + auth.user.last_name[0]).toUpperCase()
    }
    return 'U'
  }
  
  
  return (
    <>
      <AppBar position="fixed" color="inherit" sx={{ boxShadow: 0 }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters variant="dense">
            {
              // This is the non-collapsed menu when the view is medium or larger
            }
            <StyledLink to="/">
              <Avatar sx={{ fontSize: 42 }} variant="rounded">א‎</Avatar>
              <Typography variant="h6" noWrap
                          sx={{ ml: 2, mr: 2, display: { xs: 'none', md: 'flex' } }}>
                ALEF
              </Typography>
            </StyledLink>
            
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="medium" aria-label="menu options"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenNavMenu}
                          color="inherit"
              >
                <MenuIcon/>
              </IconButton>
              
              {
                // This is the collapsed menu as dropdown when the view is small
              }
              <Menu id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={Boolean(anchorElNav)}
                    onClose={() => handleCloseNavMenu('')}
                    sx={{ display: { xs: 'block', md: 'none'}}}
              >
                {routes.map((route) => (
                  <StyledLink to={route.loc} key={route.label} sx={{minHeight: 'inherit'}}>
                    <MenuItem onClick={() => handleCloseNavMenu(route.loc)} sx={{ textDecoration: 'none', height: '100%'}}>
                      <Typography textAlign="center">{route.label}</Typography>
                    </MenuItem>
                  </StyledLink>
                ))}
              </Menu>
            </Box>
            <Typography variant="h5"
                        sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}
            >
              ALEF
            </Typography>
            {
              // This is the non-collapsed items. Note they need to appear before the collapsed to make the layout look right.
            }
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, minHeight: 'inherit' }}>
              {routes.map((route) => (
                <Link to={route.loc} key={route.label} >
                  <ToggleButton size="small" selected={route.loc === location.pathname} aria-label={route.label} value={route.label}
                                sx={{ ml: 0, minHeight: 'inherit', height: '100%'}}>
                    {route.icon()}
                    {route.label}
                  </ToggleButton>
                </Link>
              ))}
            </Box>
            <Avatar sx={{ fontSize: 16, cursor: 'pointer' }} onClick={() => {
              nav('/user')
            }}>{getUserInits()} ‎</Avatar>
            <IconButton size="large" aria-label="menu options"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => handleLogout()}
            >
              <LogoutIcon/>
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      
      <Container maxWidth={false} sx={{ pt: 8, pl: 1 }}>
        <Outlet/>
      </Container>
    </>
  );
}
export default MainViewLayout;
