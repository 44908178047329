/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Signup page - A form that allows a new user to signup for an ALEF account
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { useAuth } from '../providers/Auth';
import packageJson from '../../package.json';
import { getLogger } from '../config/LogConfig';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.background.paper
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50ch'
  },
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));


const SignUp = () => {
  const log = getLogger('views.signup');
  let auth = useAuth();
  let navigate = useNavigate();
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [userIDError, setUserIDError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [genericError, setGenericError] = useState('')
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let firstName = data.get('firstName') as string;
    let lastName = data.get('lastName') as string;
    let userID = data.get('userID') as string;
    let email = data.get('email') as string;
    let password = data.get('password') as string;
    let confirmPassword = data.get('confirmPassword') as string;
    
    
    if (validateFields(firstName, lastName, userID, email, password, confirmPassword)) {
      handleSignup(firstName, lastName, userID, email, password)
    }
  };
  
  const validateName = (nameStr: string): string => {
    // First and Last Name validation
    if (!nameStr) return 'Name cannot be empty'
    if (nameStr.length > 50) return 'Name cannot exceed 50 characters'
    if (!/^[a-zA-Z]+$/.test(nameStr)) return 'Name can only contain letters.'
    return ''
  }
  
  const validateUserID = (userID: string): string => {
    // User ID validation
    if (!userID) return 'UserID cannot be empty'
    if (!/^\w{3,20}$/.test(userID)) return 'UserID can contain only letters, numbers, and underscores and must be between 3 and 20 characters'
    return ''
  }
  
  const validateEmail = (email: string): string => {
    // email validation
    if (!email) return 'Email address cannot be empty'
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!re.test(email)) return 'Please enter a valid email address'
    return ''
  }
  
  const validatePassword = (password: string, confirmPassword: string): string => {
    // password validation
    if (!password || !confirmPassword) return 'Password fields cannot be empty'
    if (password !== confirmPassword) return 'Passwords must match'
    if (!/^.{4,}$/.test(password)) return 'Password must at least 4 characters long'
    return ''
  }
  
  const validateFields = (firstName: string, lastName: string, userID: string, email: string, password: string, confirmPassword: string): boolean => {
    const firstNameError = validateName(firstName)
    const lastNameError = validateName(lastName)
    const userIDError = validateUserID(userID)
    const emailError = validateEmail(email)
    const passwordError = validatePassword(password, confirmPassword)
    setFirstNameError(firstNameError)
    setLastNameError(lastNameError)
    setUserIDError(userIDError)
    setEmailError(emailError)
    setPasswordError(passwordError)
    
    // If any fields fail to validate then return false
    return !(firstNameError || lastNameError || userIDError || emailError || passwordError);
    
  }
  
  const handleSignup = (firstName: string, lastName: string, userID: string, email: string, password: string) => {
    auth.signup(firstName, lastName, userID, email, password, (msg) => {
      log.info(`success signing up user:  ${userID}.`, msg)
      navigate('/confirm')
    }, (msg) => {
      log.error(`Error signing up user: ${userID}. `, msg)
      if (msg === 'User ID already exists') {
        setUserIDError(msg)
      } else if (msg === 'Email already exists') {
        setEmailError(msg)
      } else {
        setGenericError('An error occurred during signup.' + msg)
      }
    })
  }
  
  return (
    <Backdrop open={true} sx={{ overflowY: 'auto' }}>
      <StyledFormControl>
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography align="center" variant="h4">
              ALEF Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider sx={{ mb: 2 }}/>
                  {firstNameError && <FormHelperText error sx={{ ml: '3em' }}>{firstNameError}</FormHelperText>}
                  <StyledTextField
                    inputProps={{ 'data-testid': 'first-name-input' }}
                    variant="filled"
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    error={!!firstNameError}
                    onChange={() => setFirstNameError('')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {lastNameError && <FormHelperText error sx={{ ml: '3em' }}>{lastNameError}</FormHelperText>}
                  <StyledTextField
                    inputProps={{ 'data-testid': 'last-name-input' }}
                    variant="filled"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    error={!!lastNameError}
                    onChange={() => setLastNameError('')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {userIDError && <FormHelperText error sx={{ ml: '3em' }}>{userIDError}</FormHelperText>}
                  <StyledTextField
                    inputProps={{ 'data-testid': 'user-id-input' }}
                    variant="filled"
                    required
                    fullWidth
                    id="userID"
                    label="User ID"
                    name="userID"
                    autoComplete="username"
                    error={!!userIDError}
                    onChange={() => setUserIDError('')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {emailError && <FormHelperText error sx={{ ml: '3em' }}>{emailError}</FormHelperText>}
                  <StyledTextField
                    inputProps={{ 'data-testid': 'email-input' }}
                    variant="filled"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    error={!!emailError}
                    onChange={() => setEmailError('')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {passwordError && <FormHelperText error sx={{ ml: '3em' }}>{passwordError}</FormHelperText>}
                  <StyledTextField
                    inputProps={{ 'data-testid': 'password-input' }}
                    variant="filled"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={!!passwordError}
                    onChange={() => setPasswordError('')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    inputProps={{ 'data-testid': 'confirm-password-input' }}
                    variant="filled"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={!!passwordError}
                    onChange={() => setPasswordError('')}
                  />
                  {genericError && <FormHelperText error sx={{ ml: '3em' }}>{genericError}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sx={{ ml: 4, mr: 4 }}>
                  <Button
                    data-testid="signup-button"
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
              
              <Grid container justifyContent="flex-end">
                <Grid item sx={{ mr: 4 }}>
                  <Link component="button" variant="body2" onClick={() => navigate('/login')}>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }}/>
            </Box>
          </Box>
          <Typography align="center" mb={2} mt={2}>
            ALEF v{packageJson.version}
          </Typography> </Container>
      </StyledFormControl>
    </Backdrop>
  );
}

export default SignUp
