/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The Document Two Column Reader is dual column displayed document for reading the tl and en tiles of a document in separate panes.
 * Options include viewing your edited document, reference text (original import), machine translated text, etc.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import * as React from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { Typography } from '@mui/material';
import { DocumentService } from '../services/document_service';
import Waiting from './Waiting';
import { Language, Document, DocumentTile, Tile } from '../model/alef_model';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getLogger } from '../config/LogConfig';
import { useConfig } from '../providers/Config';
import { WarningModal } from './modals/WarningModal';
import { useNavigate } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import { tilesToStrings } from '../core/text';

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: '1em'
}));

type SegmentHolderDict = {
  [segId: string]: {
    e_segment: string
    tl_segment: string
    mt_segment: string
    ref_e_segment: string
    ref_tl_segment: string
  }
}

const DocumentTwoColumnCmp = (props: { documentId: number }) => {
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const log = getLogger('components.DocumentTwoColumnCmp');
  const [languageObj, setLanguageObj] = React.useState <Language | null>(null)
  const [document, setDocument] = React.useState<Document | null>(null)
  const [tiles, setTiles] = React.useState<{ [key: number]: Tile[] } | null>(null);
  const [refTiles, setRefTiles] = React.useState<{ [key: number]: Tile[] } | null>(null)
  const [textSource, setTextSource] = React.useState<string | null>('ETEXT')
  const [segmentHolderDict, setSegmentHolderDict] = React.useState<SegmentHolderDict>({})
  const hasDoneLookup = React.useRef(false)
  const [warningModalOpen, setWarningModalOpen] = React.useState<boolean>(false)
  let navigate = useNavigate();
  
  /**
   * Run useEffect on documentID provided via props which loads the tiles. Once tiles are loaded generate e_segment and tl_segments from
   * the tiles using makeStringGroupFromTiles on each seg group of tiles, updating the segment holder dictionary for each group.
   */
  React.useEffect(() => {
    const do_tile_lookup = async () => {
      log.info('Do lookup: ');
      log.info(JSON.stringify(doc_service))
      hasDoneLookup.current = true
      doc_service.documentTiles(props.documentId, (docTile: DocumentTile) => {
          setDocument(docTile.document)
          // Build the tiles dict to store in state
          const tileList: Tile[] = docTile.tiles
          const tileDict: { [key: number]: Tile[] } = {}
          tileList.forEach((tile: Tile) => {
            const segId = tile.seg_id
            if (!tileDict[segId]) tileDict[segId] = []
            tileDict[segId].push(tile)
          })
          setTiles(tileDict)
          // If reference tiles exist build the ref tiles dict to store in state
          const refTileList: Tile[] = docTile.ref_tiles
          const refTileDict: { [key: number]: Tile[] } = {}
          refTileList.forEach((tile: Tile) => {
            const segId = tile.seg_id
            if (!refTileDict[segId]) refTileDict[segId] = []
            refTileDict[segId].push(tile)
          })
          setRefTiles(refTileDict)
          // Set the Language OBJ state
          const langObj = docTile.document?.language ?? null;
          setLanguageObj(langObj)
        },
        (msg: string) => {
          log.error(`Got an error on text lookup: ${msg}`)
          // Open Modal if user should not have permission
          // TODO: ONLY OPEN MODAL IF REASON IS ACCESS DENIED
          setWarningModalOpen(true)
        })
    }
    if (!tiles || !refTiles) {
      if (!hasDoneLookup.current) {
        do_tile_lookup()
      }
    } else {
      Object.entries(tiles).forEach(([segId, tiles]): void => {
        const { eStr, tlStr } = tilesToStrings(tiles, true);
        updatedSegmentHolder(segId, eStr, tlStr, 'Loading...')
      })
      Object.entries(refTiles).forEach(([segId, rTiles]): void => {
        const { eStr, tlStr } = tilesToStrings(rTiles, true);
        updatedSegmentHolder(segId, undefined, undefined, 'Loading...', eStr, tlStr)
      })
    }
    return () => {
    };
  }, [props.documentId, tiles, refTiles, languageObj, doc_service, log]);
  
  /**
   * General function to update the segment holder with optionally provided segments
   * @param segId The segment id of the segment to change in the segment holder
   * @param e the e segment as a string (optional)
   * @param tl the tl segment as a string (optional)
   * @param mt the mt segment as a string (optional)
   * @param ref_e the reference e segment as a string (optional)
   * @param ref_tl the reference tl segment as a string (optional)
   */
  const updatedSegmentHolder = (segId: string, e?: string | null, tl?: string | null, mt?: string | null, ref_e?: string | null, ref_tl?: string | null) => {
    setSegmentHolderDict(prevState => ({
      ...prevState,
      [segId]: {
        ...prevState[segId],
        e_segment: e || prevState[segId]?.e_segment,
        tl_segment: tl || prevState[segId]?.tl_segment,
        mt_segment: mt || prevState[segId]?.mt_segment,
        ref_e_segment: ref_e || prevState[segId]?.ref_e_segment,
        ref_tl_segment: ref_tl || prevState[segId]?.ref_tl_segment,
      }
    }))
  }
  
  /**
   * Makes a row JSX Elements that included e_segments and tl_segments. If Reference is set to source then make grid with refTiles.
   */
  const makeGrid = () => {
    const grid_rows: JSX.Element[] = []
    if (textSource === 'REF') {
      if (refTiles) {
        Object.keys(refTiles).forEach((segId: string, index: number) => {
          grid_rows.push(makeLineControls(parseInt(segId), index))
        })
      }
    } else {
      if (tiles) {
        Object.keys(tiles).forEach((segId: string, index: number) => {
          grid_rows.push(makeLineControls(parseInt(segId), index))
        })
      }
    }
    return grid_rows
  }
  
  /**
   * Returns a JSX element for a given segId
   * @param segId the segId to build a JSX object for
   * @param index the index of the segId in the group of segments starting from 0
   */
  const makeLineControls = (segId: number, index: number): JSX.Element => {
    const direction = languageObj ? (languageObj.rtl ? 'rtl' : 'ltr') : 'ltr';
    return (
      <Grid container spacing={2} key={index}>
        <Grid xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ pr: 1, fontWeight: 'bold' }}>{`${index + 1}.`}</Typography>
          <StyledTypography dir="ltr">
            {textSource === 'MT' ? segmentHolderDict[segId]?.mt_segment :
              textSource === 'REF' ? segmentHolderDict[segId]?.ref_e_segment : segmentHolderDict[segId]?.e_segment}
          </StyledTypography>
        </Grid>
        <Grid xs={6} sx={{
          display: 'flex', flexDirection: 'row', justifyContent: direction === 'rtl' ? 'end' : 'flex-start', alignItems: 'flex-start'
        }}>
          {direction === 'rtl' ?
            (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <StyledTypography
                className={languageObj ? languageObj.name : 'Unknown'}>
                {textSource === 'REF' ? segmentHolderDict[segId]?.ref_tl_segment : segmentHolderDict[segId]?.tl_segment}
              </StyledTypography>
              <Typography sx={{ pl: 1, fontWeight: 'bold', direction: 'rtl' }}>{`${index + 1}.`}</Typography>
            </Box>)
            :
            (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <Typography sx={{ pr: 1, fontWeight: 'bold' }}>{`${index + 1}.`}</Typography>
              <StyledTypography
                className={languageObj ? languageObj.name : 'Unknown'}>
                {textSource === 'REF' ? segmentHolderDict[segId]?.ref_tl_segment : segmentHolderDict[segId]?.tl_segment}
              </StyledTypography>
            </Box>)
          }
        </Grid>
      </Grid>)
  };
  
  const handleModalClose = () => {
    setWarningModalOpen(false)
    navigate('/documents')
  }
  
  if (warningModalOpen) {
    return <WarningModal isOpen={warningModalOpen} handleClose={handleModalClose}
                         modalHeader={'Operation Not Permitted'}
                         warningMessage={'This document does not exist or you do not have access privileges.'}/>
  }
  
  if (!document || !languageObj) {
    return <Waiting/>
  }
  
  /**
   * This function calls translateSegments on the TL segment text generated from the tiles.
   */
  const handleUseMT = () => {
    const tl_segments: { [segId: string]: string } = Object.entries(segmentHolderDict).reduce<{
      [segId: string]: string
    }>((acc, [segId, entry]) => {
      acc[segId] = entry.tl_segment
      return acc
    }, {})
    const translationNotAvailable = (segIds: string[]): void => {
      segIds.forEach((segId: string) => {
        updatedSegmentHolder(segId, null, null, 'Translation not available')
      })
    }
    // Only translate segments if mt_segments is none or 'Loading...' in the segment holder and useMT is checked
    if (Object.values(segmentHolderDict).every(entry => !entry.mt_segment || entry.mt_segment === 'Loading...')
      && document.mt_allowed) {
      //if (Object.values(tl_segments).length > 10) return // TODO: Need better way to translate segments.
      doc_service.translateSegments(Object.values(tl_segments), languageObj, (result) => {
        log.info(`Got a result: ${JSON.stringify(result)}`);
        
        const segIds: string[] = Object.keys(tl_segments) // map the keys to the translated segments
        result.translated_segments.forEach((segment: string, index: number) => {
          const correspondingSegId: string = segIds[index]
          updatedSegmentHolder(correspondingSegId, null, null, segment)
        })
      }, (msg: string) => {
        log.error(`Got an error on text lookup: ${msg}`)
        translationNotAvailable(Object.keys(tl_segments))
      })
    } else if (!document.mt_allowed) {
      translationNotAvailable(Object.keys(tl_segments))
    }
  }
  
  /**
   * Handles switching the Source Control (User tiles, MT Tiles, and Reference Tiles)
   * @param event The click event
   * @param newTextSource The value of the selected push button
   */
  const handleETextSource = (event: React.MouseEvent<HTMLElement>, newTextSource: string | null) => {
    setTextSource(newTextSource);
    if (newTextSource === 'MT') handleUseMT()
  };
  
  /**
   * Simple function to determine whether refTiles contains reference material.
   */
  const refTilesAvailable = (): boolean => {
    if (!refTiles) return false
    return Object.keys(refTiles).length > 0
  }
  
  return (
    <Box>
      <Box
        sx={{
          pt: 6,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          width: '100%'
        }}>
        
        <Box flex="1" sx={{ width: 'fit-content' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <ToggleButtonGroup
              value={textSource}
              exclusive
              onChange={handleETextSource}
              size={'small'}
            >
              {(refTilesAvailable() || document.mt_allowed) && <ToggleButton value="ETEXT">
                {'Your Edits'}
              </ToggleButton>}
              {document.mt_allowed &&
                <ToggleButton value="MT">
                  {'Machine Translation'}
                </ToggleButton>}
              {refTilesAvailable() &&
                <ToggleButton value="REF">
                  {'Reference Text'}
                </ToggleButton>
              }
            
            </ToggleButtonGroup>
          </Box>
          {textSource === 'MT' && (
            <Box sx={{ width: '100%', display: 'flex' }}>
              <Box sx={{
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: 1,
                pt: 0.25,
                mb: 0.5,
                borderTop: 1,
                borderColor: 'grey.200',
              }}>
                <Typography sx={{ pr: '1em', color: 'gray', fontSize: 'small' }}>
                  {'Machine Translation of Your Edits'}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    width: 'fit-content',
                    
                    // mt: 0.5,
                    mb: 0,
                    // pt: 1,
                    // pb: 2,
                  }}>
                  <img src={process.env.PUBLIC_URL + '/img/gt-color-regular.png'} alt="Translated by Google"/>
                </Typography>
              </Box>
            </Box>
          
          )}
        
        
        </Box>
        <Box flex="1">
          <Typography variant="h6" sx={{ pb: 0, textAlign: 'center' }}>"{document.title}"</Typography>
          <Typography variant="h6" sx={{ pt: 1, pb: 2, textAlign: 'center' }}>by {document.author}</Typography>
        </Box>
        <Box flex="1">
          {/* Spacer */}
        </Box>
      </Box>
      {makeGrid()}
    </Box>
  );
};

export default DocumentTwoColumnCmp
