/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Dialog for sharing documents with another user.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as React from 'react';
import { DocumentService } from '../../../services/document_service';
import { useConfig } from '../../../providers/Config';


const ShareDialog = (params: any) => {
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const [value, setValue] = React.useState('')
  
  const handleShare = () => {
    console.log('Sharing docID: ', params.docId, ' with userId: ', value)
    // run share service call
    doc_service.documentShare(params.docId, value,
      (msg) => {
        console.log('Success', msg)
      },
      (msg) => {
        console.error('Error', msg)
      })
    
    params.handleShareClose()
    setValue('')
  }
  
  const handleCancel = () => {
    params.handleShareClose()
    setValue('')
  }
  
  return (
    <Dialog open={params.open}
    >
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <Container style={{ height: '100px', display: 'flex', flexDirection: 'row' }}>
          <TextField
            sx={{ m: 2 }}
            autoFocus
            label="user id"
            fullWidth
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <Button onClick={handleShare}>OK</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Container>
      </DialogContent>
    </Dialog>
  )
}
export default ShareDialog
