/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Forgot Password View.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { useAuth } from '../providers/Auth';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import { getLogger } from '../config/LogConfig';
import packageJson from '../../package.json';
import { FormHelperText } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';


const log = getLogger('views.Login');

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.background.paper
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50ch'
  },
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  paddingInline: theme.spacing(6)
}))

const ForgotPassword = () => {
  let auth = useAuth();
  const [email, setEmail] = React.useState<string>('')
  const [emailErr, setEmailErr] = React.useState<string>('');
  const [confirmation, setConfirmation] = React.useState<boolean>(false)
  
  const handleKeydown = (key: string) => {
    if (key === 'Enter') {
      handleSendPasswordReset()
    }
  }
  
  const validateEmail = (email: string): string => {
    let err = ''
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!email) err = 'Email address cannot be empty'
    else if (!re.test(email)) err = 'Please enter a valid email address'
    setEmailErr(err)
    return err
  }
  
  const handleSendPasswordReset = () => {
    if (validateEmail(email)) return
    
    log.info('Sending password reset link request for: ', email)
    console.log('Sending password reset link request for: ', email)
    auth.makePasswordResetLink(email,
      (msg) => {
        console.log('Success', msg)
        setConfirmation(true)
      },
      (msg) => {
        console.log('Failure', msg)
        setConfirmation(true)
      },
    )
  }
  
  if (confirmation) return (
    <Backdrop open={true} sx={{ overflowY: 'auto' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper sx={{ paddingY: 8, paddingX: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography align="center" mb={2} mt={2} variant="h4">
            ALEF Password Reset
          </Typography>
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography sx={{ pt: 1, width: 'fit-content' }}>
            If you have an account, you will receive an email shortly.
          </Typography>
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography align="center">
            ALEF v{packageJson.version}
          </Typography>
        </Paper>
      </Container>
    </Backdrop>
  )
  
  
  return (
    <>
      <Backdrop open={true}>
        <StyledFormControl>
          <Typography align="center" mb={2} mt={2} variant="h4">
            ALEF Forgot Password
          </Typography>
          <Divider/>
          
          <StyledTextField
            required={true}
            variant="filled"
            label="Email Address"
            error={!!emailErr}
            onKeyDown={(e) => handleKeydown(e.key)}
            onChange={(event) => setEmail(event.target.value.trim())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon/>
                </InputAdornment>
              )
            }}
            inputProps={{ 'data-testid': 'email-text-field' }}
          />
          {emailErr && <FormHelperText error sx={{ ml: '3em' }} data-testid="error-text">{emailErr}</FormHelperText>}
          
          
          <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
            <StyledButton color="primary" variant="outlined" fullWidth={true}
                          onClick={handleSendPasswordReset} data-testid="reset-button">
              Send Password Reset
            </StyledButton>
          </Stack>
          
          
          <Divider sx={{ mt: 2 }}/>
          <Typography align="center" mb={2} mt={2}>
            ALEF v{packageJson.version}
          </Typography>
        
        </StyledFormControl>
      </Backdrop>
    </>
  );
}


export default ForgotPassword
