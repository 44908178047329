/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Content displayed on user profile page including a tab panel.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import Box from '@mui/material/Box';
import AccountSettings from './AccountSettings'
import { Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Actions from './Actions';
import { User } from '../../../model/user';
import MyDocuments from './MyDocuments';
import SharedDocuments from './SharedDocuments';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ContentProps {
  user: User
}

const Content = ({ user }: ContentProps) => {
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  return (
    <Box style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Account Settings"/>
          <Tab label="My Documents"/>
          <Tab label="Shared Documents"/>
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <AccountSettings user={user}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MyDocuments user={user}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SharedDocuments user={user}/>
        </CustomTabPanel>
      </Box>
      
      {(value === 0) && <Box sx={{}}><Actions/></Box>}
    </Box>
  );
};

export default Content
