/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The Hypergloss Editor View (Deep Translation) contains the Cardsorter view. Displays segments below which may be selected
 * to change the current working segment. Segments may be modified in the Cardsorter by dragging and dropping or directly editing
 * the tl or en tokens within the tiles (SortableGameCards).
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React, {useCallback, useEffect} from 'react';
import {Fab, ToggleButtonGroup, Tooltip, Typography} from '@mui/material';
import {DocumentService} from '../services/document_service';
import Waiting from './Waiting';
import {Document, DocumentTile, Language, Tile} from '../model/alef_model';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/system';
import Box from '@mui/material/Box';
import CardSorter, {CardSorterRef} from '../views/main/CardSorter';
import {DeepPartial, OrderedTileDict, TileDict} from '../model/cardsorter_model';
import Button from '@mui/material/Button';
import GTranslateIcon from '@mui/icons-material/GTranslate'
import NextIcon from '@mui/icons-material/NavigateNext';
import BeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import {getLogger} from '../config/LogConfig';
import {
  unstable_BlockerFunction as BlockerFunction,
  unstable_useBlocker as useBlocker,
  useNavigate
} from 'react-router-dom';
import {useConfig} from '../providers/Config';
import {WarningModal} from './modals/WarningModal';
import {tilesToString} from '../core/text';
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";
import ToggleButton from "@mui/material/ToggleButton";
import {KeyboardShortcuts} from "./tooltips/KeyboardShortcuts";
import InfoIcon from "@mui/icons-material/Info";

// TODO Re-enable if we support multiple segments
// const SegmentTypography = styled(Typography)`
//   background: white;
//   padding-inline: 0.25em;
//
//   :hover {
//     background: #ddd;
//     cursor: pointer;
//   }
// `;

const RootPanel = styled('div')`
    position: relative;
    height: calc(100vh - 81px);
    width: auto;
`

const LeftColumn = styled('div')`
    position: absolute;
    top: 25px;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
`
const RightColumn = styled('div')`
    position: absolute;
    top: 25px;
    bottom: 0;
    right: 10px;
`
const HyperglossEditCmp = (props: { documentId: number , initialSegment: number}) => {
  const log = getLogger('components.hyperglossEditCmp');
  const { config } = useConfig();
  const show_my_tl = 'show-my-tl'
  const show_my_en = 'show-my-en'
  const show_mt_en = 'show-mt-en'
  const show_ref_en = 'show-ref-en'
  const show_ref_tl = 'show-ref-tl'
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const [currSegment, setCurrentSegment] = React.useState<number | null>(null);
  const [translatedText, setTranslatedText] = React.useState<string>('')
  const [languageObj, setLanguageObj] = React.useState <Language | null>(null)
  const [document, setDocument] = React.useState<Document | null>(null)
  const originalTilesRef: React.MutableRefObject<TileDict> = React.useRef({})
  const originalTilesOrderedRef: React.MutableRefObject<OrderedTileDict> = React.useRef({})
  const [tilesDict, setTilesDict] = React.useState<TileDict>({})
  const [tilesOrdered, setTilesOrdered] = React.useState<OrderedTileDict>({})
  const hasDoneLookup = React.useRef(false)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isMoveNext, setIsMoveNext] = React.useState<boolean>(false)
  const [isMovePrev, setIsMovePrev] = React.useState<boolean>(false)
  const [isReloading, setIsReloading] = React.useState<boolean>(false)
  const [isTranslating, setIsTranslating] = React.useState<boolean>(false)
  const [warningModalOpen, setWarningModalOpen] = React.useState<boolean>(false)
  const [tileOrder, setTileOrder] = React.useState<string>("tl")
  const [visiblePanels, setVisiblePanels] = React.useState<string[]>([show_ref_tl, show_my_en, show_mt_en, show_ref_en])
  let navigate = useNavigate();
  const cardSorterRef = React.useRef<CardSorterRef>(null);
  const [refTiles, setRefTiles] = React.useState<{ [key: number]: Tile[] }>({})

  const isDirty = React.useCallback(() => {
    return Object.values(tilesOrdered).some(tileInfo => tileInfo.modified) ?? false
  }, [tilesOrdered])

  useBlocker(
    React.useCallback<BlockerFunction>(
      ({ currentLocation, nextLocation }) => {
        const nextRootIdx = nextLocation.pathname.indexOf('translation');
        const nextPathRoot = nextRootIdx > -1 ? nextLocation.pathname.substring(0, nextRootIdx) : "";
        const currRootIdx = currentLocation.pathname.indexOf('translation');
        const currPathRoot = currRootIdx > -1 ? currentLocation.pathname.substring(0, currRootIdx) : "";

        // log.info(`Moving from ${currPathRoot} to ${nextPathRoot}`)

        if (nextPathRoot !== currPathRoot && isDirty()) {
          return !window.confirm('You have unsaved changes! Are you sure you want to leave?')
        }
        return false
      },
      [isDirty]
    )
  )


  /**
   *   UseEffect to add the warning prompt for refresh and url changes.
   */
  useEffect(() => {
    /**n
     * Handles the before unload. Prompting the user to leave or stay on the page to not lose any edits that have been made.
     * @param e the BeforeUnloadEvent
     */
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Check if any segment is modified
      if (Object.values(tilesOrdered).some(tileInfo => tileInfo.modified) ?? false) {
        e.preventDefault()
        e.returnValue = 'You have unsaved changes! Are you sure you want to leave?'
      }
    }
    // Attach the listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [tilesOrdered])

  const loadRefTilesToState = React.useCallback((refTileList: Tile[]) => {
    const refTileDict: { [key: number]: Tile[] } = {}
    refTileList.forEach((tile: Tile) => {
      const segId = tile.seg_id
      if (!refTileDict[segId]) {
        refTileDict[segId] = []
      }
      refTileDict[segId].push(tile)
    })
    for (const segId in refTiles) {
      refTiles[segId].sort((a, b) => a.en_loc - b.en_loc)
    }
    setRefTiles(refTileDict)
  }, [refTiles]);

  /**
   * Helper function for managing state of tile data.
   * Make two dicts for both fast access to tile and keeping order for tiles within segments
   * @param updatedTiles the tile data from an api request
   */
  const loadTilesToState = React.useCallback((updatedTiles: Tile[]) => {
    let tilesDict: TileDict = {}
    let tileOrderedDict: OrderedTileDict = {}
    let currSegId: number | null = null
    let checkSegNum: number = 0
    updatedTiles.forEach((tile: Tile): void => {
      tile.modified = false
      // Add tile to document level tilesDict
      tilesDict[tile.id] = tile
      // Add tiles to the segment level tilesOrdered dict of tileId arrays
      const segId = tile.seg_id;
      if (!tileOrderedDict[segId]) tileOrderedDict[segId] = { tileIds: [], modified: false }
      tileOrderedDict[segId].tileIds.push(tile.id)
      if (checkSegNum < props.initialSegment) {
        if (tile.seg_id !== currSegId) {
          currSegId = tile.seg_id;
          checkSegNum++;
        }
      }
    })
    // Sort the tiles within each segment grouping
    for (const segId in tileOrderedDict) {
      tileOrderedDict[segId].tileIds.sort((a, b) => tilesDict[a]?.en_loc - tilesDict[b]?.en_loc)
    }
    setTilesDict(tilesDict)
    setTilesOrdered(tileOrderedDict)
    // Also set the original refs so that tile comparisons can be made to check for modification to originals
    originalTilesRef.current = tilesDict
    originalTilesOrderedRef.current = tileOrderedDict
    setCurrentSegment(currSegId)

    //setCurrentSegment(Number(tilesOrdered[Object.keys(tilesOrdered)[props.initialSegment-1]].tileIds))
  }, [props.initialSegment])

  const lookupDocumentTiles = useCallback(() => {
    let docId = props.documentId;
    log.info('Do lookup');
    hasDoneLookup.current = true
    doc_service.documentTiles(docId, (docTile: DocumentTile) => {
        log.info('Setting tiles in card sorter')
        loadTilesToState(docTile.tiles)
        setDocument(docTile.document)
        loadRefTilesToState(docTile.ref_tiles)

        // Setup the visible panels
        let visible_p = [show_my_en]
        if (docTile.document?.mt_allowed) {
          visible_p.push(show_mt_en);
        }

        if (docTile.ref_tiles.length > 0) {
          visible_p.push(show_ref_tl);
          visible_p.push(show_ref_en);
        }

        setVisiblePanels(visible_p)
        setLanguageObj(docTile.document.language)
        setIsReloading(false)
      },
      (msg: string) => {
        log.error(`Got an error on text lookup: ${msg}`)
        setIsReloading(false)
        // Open Modal if user should not have permission
        // TODO: ONLY OPEN MODAL IF REASON IS ACCESS DENIED
        setWarningModalOpen(true)
      }
    );
  }, [doc_service, loadRefTilesToState, loadTilesToState, props.documentId, log]);

  /**
   * Upon render of the Hypergloss Editor Component, all user tiles for the document chosen will be fetched from
   * the database and saved to state. They come in as docTiles {Document, Tile[]}. The Tiles are mapped
   * to a dictionary for easy lookup for modification of tile properties. An ordered array of the tile ids is created
   * for keeping track of how the tiles should be arranged on the screen.
   */
  useEffect(() => {
    if (!props.documentId) {
      return
    }

    if (Object.keys(tilesDict).length === 0 && !hasDoneLookup.current) {
      lookupDocumentTiles()
    }
  }, [tilesDict, props.documentId, log, doc_service, loadTilesToState, lookupDocumentTiles])
  
  /**
   * Compares equivalency between two objects. Made specifically for tile comparisons. Special case: e_token and term comparison will result
   * true when an e_token with an empty string is compared to a null e_token value. Strips the modified field from the object
   * before comparison.
   * @param objA The first object (tile) to compare
   * @param objB The second object (tile) to compare
   */
  const areObjectsEquivalent = React.useCallback((objA: any, objB: any): boolean => {
    // Remove modified from object
    const { modified: _, ...aWithoutModified } = objA
    const { modified: __, ...bWithoutModified } = objB
    const keysA: string[] = Object.keys(aWithoutModified)
    const keysB: string[] = Object.keys(bWithoutModified)
    // Return false if key length is unequal
    if (keysA.length !== keysB.length) return false
    // Loop through each key and check for equivalence
    for (const key of keysA) {
      const valA = aWithoutModified[key]
      const valB = bWithoutModified[key]
      // Special case for e_token. Consider empty string and null to be equivalent. (do nothing)
      if (key === 'e_token') {
        if (valA && valA.term === '' && valB === null) continue;
        else if (valB && valB.term === '' && valA === null) continue;
      }
      // Check non e_token parameters for equivalency
      if (typeof valA === 'object' && valA !== null && typeof valB === 'object' && valB !== null) {
        if (!areObjectsEquivalent(valA, valB)) return false // Recurse nested objects
      } else if (valA !== valB) {
        return false
      }
    }
    return true
  }, [])
  
  /**
   * Updates the tiles ordered state for the segments in the updates array provided.
   * @param updates is an array of objects which include a segId and the updatedTileIds number array of tile Ids to be set to state.
   * @param saving flag to set all updated segments to modified false by default
   */
  const handleUpdateTileIds = React.useCallback((updates: { segId: number, updatedTileIds: number[] }[], saving: boolean = false): void => {
    setTilesOrdered(prevState => {
      let newState: OrderedTileDict = { ...prevState }
      // Process each update
      //log.info("handleUpdateTileIds")
      updates.forEach(({ segId, updatedTileIds }) => {
        const currentTileIds: number[] = originalTilesOrderedRef.current[segId].tileIds
        const areSame: boolean = areObjectsEquivalent(updatedTileIds, currentTileIds)
        //log.info(`handleUpdateTileIds: areSame=${areSame}\noldIds: ${currentTileIds}\nupdIds: ${updatedTileIds}`)
        if (newState[segId]) {
          newState[segId] = {
            ...newState[segId],
            tileIds: updatedTileIds,
            modified: saving ? false : !areSame
          }
        }
      })
      return newState
    })
  }, [setTilesOrdered, areObjectsEquivalent])

  const handleMoveTile = (targetSegId: number, dragIndex: number, hoverIndex: number, insertBefore: boolean) => {
    // TODO: Consider keeping en_loc as invalid until right before sending to api.
    if (dragIndex === hoverIndex) {
      return
    }
    const workingTileIds: number[] = [...tilesOrdered[targetSegId].tileIds]
    if (workingTileIds) {
      // Update the position in the tileIds array
      const dragTileId: number = workingTileIds[dragIndex]

      // Create a copy of the IDs.
      let updatedTileIds = workingTileIds.slice();

      // Remove the drag item
      updatedTileIds.splice(dragIndex, 1);

      // Find the lowest (start) and highest (end) positions in the array involved in the move. This way we can update
      // all tiles between.
      let startIndex = hoverIndex;
      let endIndex = dragIndex;
      if (dragIndex < hoverIndex) {
        startIndex = dragIndex;
        endIndex = hoverIndex;
      }

      // When we are inserting before, we should shift left one.
      let adjHoverIndex = insertBefore && hoverIndex > 0 ? hoverIndex-1 : hoverIndex;
      updatedTileIds.splice(adjHoverIndex, 0, dragTileId);
      // log.info(`Drag startIndex=${startIndex}, endIndex=${endIndex}, adjHoverIndex: ${adjHoverIndex}, tile=${dragTileId} from di=${dragIndex} to tile=${hoverTileId} at hi=${hoverIndex} ahi=${adjHoverIndex}`)
      // log.info(`Before Working IDs: ${workingTileIds}`)
      // log.info(`After Working IDs:  ${updatedTileIds}`)
      handleUpdateTileIds([{segId: targetSegId, updatedTileIds: updatedTileIds}])

      // Update en_loc of tiles from the start to end index. Set moving param to true to not update tilesOrdered while state change is occurring (race condition)
      for (let i = startIndex; i <= endIndex; i++) {
        updateTile(updatedTileIds[i], {en_loc: i}, true)
      }
    }
  }

  /**
   * Updates a single segment in the tilesOrdered object, setting the modified flag for a particular segment.
   * @param segId The segment id of the segment to update the status of
   * @param modified Status to update the segment modified flag to. (True by default)
   */
  const setSegmentAsModified = React.useCallback((segId: number, modified: boolean = true) => {
    const updatedTilesOrdered: OrderedTileDict = { ...tilesOrdered };
    if (updatedTilesOrdered[segId]) {
      updatedTilesOrdered[segId].modified = modified
    }
    setTilesOrdered(updatedTilesOrdered)
  }, [tilesOrdered, setTilesOrdered])
  
  /**
   * A helper function for updating a tile
   */
  const deepMerge = React.useCallback((obj1: any, obj2: any) => {
    const output = { ...obj1 }
    Object.keys(obj2).forEach((key) => {
      if (obj2[key] !== null && typeof obj2[key] == 'object') {
        output[key] = deepMerge(output[key], obj2[key])
      } else {
        output[key] = obj2[key]
      }
    })
    return output
  }, [])
  
  
  /**
   * Using the deepMerge helper function above, update the tile in the tilesDict by lookup using
   * the tileId (the id of the tile within the tile). The new properties are a DeepPartial type of tile.
   * @param tileId the id of the tile within the tile object
   * @param newProperties the object that contains the newProperties to update the tile with
   * @param moving is being called due to moving (drag and drop) of a tile. (avoids setOrderedTiles race condition)
   */
  const updateTile = React.useCallback((tileId: number, newProperties: DeepPartial<Tile>, moving: boolean = false): void => {
    // @ts-ignore
    setTilesDict((prevDict: TileDict | null) => {
      if (!prevDict) return prevDict
      const currentTile: Tile = prevDict[tileId]
      if (!currentTile) return prevDict
      const updatedTile: Tile = deepMerge(currentTile, newProperties) // instead of doing deep merge just parameterize?
      // Extract the modified flag for proper comparison and setting of modified flag for updated tile
      const isModified: boolean = !areObjectsEquivalent(updatedTile, originalTilesRef.current[tileId])
      updatedTile.modified = isModified
      // Set the segment as modified for makeGrid
      if (!moving) {
        if (isModified) {
          setSegmentAsModified(currentTile.seg_id)
        } else {
          // Check if any other tiles in the segment are modified and if not set the segment to not modified
          const othersModifed = tilesOrdered[currentTile.seg_id].tileIds.some((id: number) => {
            if (id === tileId) return false // exclude current not yet changed tile
            return tilesDict[id].modified
          })
          if (!othersModifed) setSegmentAsModified(currentTile.seg_id, false)
        }
      }
      return { ...prevDict, [tileId]: updatedTile }
    })
  }, [deepMerge, tilesDict, tilesOrdered, setTilesDict, setSegmentAsModified, areObjectsEquivalent])
  
  const getSegmentTLText = () => currSegment ? getSegmentFromTiles(tilesOrdered[currSegment].tileIds, true) : 'Select a sentence'
  const getSegmentEnText = () => currSegment ? getSegmentFromTiles(tilesOrdered[currSegment].tileIds, false) : 'Select a sentence'
  const getSegmentReferenceEnText = () => currSegment ? tilesToString(refTiles[currSegment], true, false) : 'Select a sentence'

  const getSegmentReferenceTLText = (): string => {
    if (!currSegment) {
      return 'Select a sentence';
    }
    const tempTiles = [...refTiles[currSegment]]; // So we don't sort the state array
    const tiles: Tile[] = tempTiles.sort((a: Tile, b: Tile) => (a.tl_loc - b.tl_loc));
    return tilesToString(tiles, true, true);
  }

  const handleReloadClick = () => {
    if (!isReloading && !isUIControlDisabled()) {
      if (isDirty() && window.confirm('Are you sure you want to discard your changes and reload the saved document?')) {
        setIsReloading(true)
        lookupDocumentTiles()
      }
    }
  };

  const getDirection = () => {
    // Default to ltr when we have no language or tiles.
    return tilesOrdered && languageObj && languageObj.rtl ? 'rtl' : 'ltr'
  }

  const getAlignment = () => {
    // Default to ltr when we have no language or tiles.
    return languageObj && languageObj.rtl ? 'right' : 'left'
  }

  const getLanguage = () => {
    return tilesOrdered && languageObj ? languageObj.name : 'Unknown'
  }

  const getTrigraph = () => {
    return tilesOrdered && languageObj ? languageObj.trigraph: 'UNK'
  }
  
  const getSegmentFromTiles = (tileIds: number[], retTL: boolean): string => {
    if (!tileIds) {
      return '';
    }
    const tempTileIds = [...tileIds]; // So we don't sort the state array
    const tiles: Tile[] = tempTileIds
      .map((id: number) => tilesDict[id])
      .sort((a: Tile, b: Tile) => retTL ? (a.tl_loc - b.tl_loc) : (a.en_loc - b.en_loc));
    return tilesToString(tiles, true, retTL);
  }

  const changeSegment = (segId: string) => {
    setCurrentSegment(parseInt(segId, 10))
    setTranslatedText('') // Set the english machine translation to empty string
    setIsTranslating(false)
    // This did set the mt_text from the segment but we are no longer using segments
    // TODO: make query/endpoint for retrieving mt of single segment
    // if (s) {
    //   setTranslatedText(s.mt_text);
    // }
  }

  // RHB 2024.03.28: Removed to match new translation UI for experiment.
  //                 TODO Use or remove.
  // const makeGrid = () => {
  //   if (tilesOrdered) {
  //     const direction = getDirection()
  //     //let check_len = text.e_lines.length > text.tl_lines.length ? text.e_lines.length : text.tl_lines.length
  //     return Object.entries(tilesOrdered).map(([segId, tileInfo]) =>
  //       (<SegmentTypography key={segId} data-testid={`hypergloss-segment-${segId}`} dir={direction}
  //                           className={languageObj ? languageObj.name : 'Unknown'}
  //                           onClick={() => changeSegment(segId)}
  //                           sx={{
  //                             borderTop: 1,
  //                             borderColor: 'grey.200',
  //                             ...(tileInfo.modified && { backgroundColor: '#ffffaa', border: '1px solid #aaaa00' }),
  //                             ...(currSegment === parseInt(segId, 10) && { backgroundColor: '#ddd' })
  //                           }}>
  //         {getSegmentFromTiles(tilesOrdered[parseInt(segId, 10)].tileIds)}
  //       </SegmentTypography>)
  //     );
  //   } else {
  //     return [];
  //   }
  // }
  //
  
  /**
   * Returns an array of segment ids that have been modified. This is useful when sending the edits
   * to the api so that only edited segments are processed.
   */
  const getModifiedSegmentsArray = React.useCallback((): number[] => {
    return Object.entries(tilesOrdered)
      .filter(([_, tileInfo]) => tileInfo.modified)
      .map(([segId]) => parseInt(segId, 10))
  }, [tilesOrdered])

  /**
   * Save the tiles to the database by sending only the edited tiles. Ensure the last edited tiles are included
   * in the db service call by calling handleSaveToTiles and using the result as the working set of tiles.
   * On success change all tiles and segments to modified: false
   */
  const saveTilesToDatabase = React.useCallback(
    (isMove: boolean, saveDoneCB: () => void) => {
      if (!document || !tilesDict || isSaving || isReloading) {
        return;
      }
      log.info(`Check for save toggle: isMoveNext: ${isMoveNext} and isMovePrev: ${isMovePrev}`);
      if (!isMove) {
        setIsSaving(true)
      }
      const modifiedSegments: number[] = getModifiedSegmentsArray()
      if (modifiedSegments.length === 0) {
        log.info('Nothing to save')
      }
      const modifiedTiles: Tile[] = Object.values(tilesDict).filter((t: Tile) => modifiedSegments.includes(t.seg_id))
      log.info(`Saving user set of tiles: ${modifiedTiles.filter(t => t.e_token && t.e_token.term !== "").map(t => t.e_token.term)}`)

      // Send all the tiles of the modified segments to the server for edits to the database set of user tiles
      doc_service.saveUserTiles(document.id, modifiedTiles, (result: {
        success: string,
        updatedTiles: Tile[]
      }) => {
        log.info(`Succeeded saving tiles to db : ${result.success}`)
        let workingTilesDict: TileDict = {...tilesDict}
        result.updatedTiles.forEach(t => {
          // Update the tile or create a new one if it does not exist
          workingTilesDict[t.id] = t
          log.info(`Succeeded saving tiles to db : ${result.success} saved token ${t.e_token?.term}`);
        })
        // change the ids of the tiles that were modified such that the negative ids become legitimate ids
        let updates: { segId: number, updatedTileIds: number[] }[] = [] // An array to hold updatedTileIds objects with segIds
        modifiedSegments.forEach(segId => {
          let workingTileIds: number[] = []
          Object.values(workingTilesDict).forEach(tile => {
            if (tile.seg_id === segId) {
              if (tile.id < 0) {
                // remove the negative id tiles from the sorted arrays of tile.id (sorted by en_loc)
                delete workingTilesDict[tile.id]
              } else {
                workingTileIds.push(tile.id)
              }
            }
          })
          // sort the array after all updatedTiles are pushed
          workingTileIds.sort((a, b) => workingTilesDict[a].en_loc - workingTilesDict[b].en_loc)
          // Push the updatedTileIds to the updates array with the correct segId
          updates.push({segId: segId, updatedTileIds: workingTileIds})
        })
        handleUpdateTileIds(updates, true) // This should only call setTilesOrdered once and avoid race conditions
        setTilesDict(workingTilesDict)
        originalTilesRef.current = workingTilesDict
        setIsSaving(false)
        saveDoneCB()
      }, (errorMessage: string) => {
        log.error('Failed saving tiles to db', errorMessage)
        setIsSaving(false)
        saveDoneCB()
      });
    }, [doc_service, document, handleUpdateTileIds, log, tilesDict, getModifiedSegmentsArray, isSaving, isMoveNext, isMovePrev, isReloading]) // No need for isSaving dependency

  /**
   * Sends a segment id to the api and sets the translated text state to the returned result.
   */
  const handleGoogleTranslateClick = (e: React.MouseEvent) => {
    if (isTranslating) {
      log.info('Cannot call google translate while translating.')
      return
    }
    log.info(`Calling google translate: ${languageObj?.name} : ${getSegmentTLText()})`);
    // call Google Translate api on segmentText and set translatedText to returned english text
    // TODO: Need to call MT on text since no longer using segment and text is rendered from tiles which change.
    if (currSegment && languageObj && document?.mt_allowed) {
      setTranslatedText('Loading...')
      setIsTranslating(true);
      doc_service.translateSegments([getSegmentTLText()], languageObj, (transResp) => {
          // This was causing all results to be ignored
          // if (isTranslating) { // Ignore the results if isTranslating is set to false when they come back
          log.info(`Received response: isTranslating: ${isTranslating}: ${JSON.stringify(transResp)})`);
          setTranslatedText(transResp.translated_segments[0])
          setIsTranslating(false)
          // }
        },
        (msg) => {
          log.error(`Got an error on translation: ${msg}`)
          setTranslatedText('Translation not available')
          setIsTranslating(false)
        });
    } else {
      setTranslatedText('Translation not available.')
    }
    return () => {
    };
    
    // update the state and React will re-render the english text
    //setTranslatedText(translatedText)
  }

  const handleModalClose = () => {
    setWarningModalOpen(false)
    navigate('/documents')
  }

  /** Handle a previous or next click. Send -1 for previous or 1 for next. Potentially allow sending any segment. */
  const handleClick = (idxOffset: number) => {
    if (currSegment != null) {
      const currSegs = Object.keys(tilesOrdered)
      const currIdx = currSegs.indexOf(currSegment.toString())
      const newIdx = idxOffset + currIdx;

      if (newIdx <= currSegs.length && newIdx >= 0) {
        const doneCB = () => {
          changeSegment(currSegs[newIdx].toString())
          navigate(`/documents/${props.documentId}/translation/${newIdx + 1}`)
          setIsMoveNext(false)
          setIsMovePrev(false)
        };
        if (isDirty()) {
         saveTilesToDatabase(true, doneCB);
        } else {
          doneCB();
        }
      }
    }
  }

  const isUIControlDisabled = () => {
    return isSaving || isReloading || isMovePrev || isMoveNext;
  }

  const handleNextClick = () => {
    if (!isMovePrev && !isReloading && !isSaving && !isMoveNext) {
      setIsMoveNext(true);
      handleClick(1);
    }
  }
  const handlePrevClick = () => {
    if (!isMovePrev && !isReloading && !isSaving && !isMoveNext) {
      setIsMovePrev(true);
      handleClick(-1);
    }
  }

  const handleCompleteClick = () => {
    if (!isMovePrev && !isReloading && !isSaving && !isMoveNext) {
      setIsMoveNext(true)
      saveTilesToDatabase(true, () => {
        setIsMoveNext(false);
        navigate(`/documents/${props.documentId}/reader`)
      });
    } else {
      log.info("No changes found. Not saving tiles")
    }
  }

  const handleSaveClick = () => {
    if (isDirty()) {
      saveTilesToDatabase(false, () => {});
    } else {
      log.info("No changes found. Not saving tiles")
    }
  }

  if (warningModalOpen) {
    return <WarningModal isOpen={warningModalOpen} handleClose={handleModalClose}
                         modalHeader={'Operation Not Permitted'}
                         warningMessage={'This document does not exist or you do not have access privileges.'}/>
  }
  
  if (!document || !tilesDict || !tilesOrdered || !currSegment) {
    return <Waiting/>
  }
  
  /** Make the google translate button in the source TL panel. Only rendered when the language is supported. */
  const makeTranslateButton = () => {
    if (languageObj && languageObj.google_translate_iso_code && isVisible(show_mt_en)) {
      return (<Tooltip title='Translate this segment with google translate'>
          <Button color='secondary' disabled={isTranslating} onClick={handleGoogleTranslateClick}
                  sx={{paddingInline: '1.25em', borderRadius: '5px'}}>
            <GTranslateIcon/>
          </Button>
        </Tooltip>
      )
    }
  }

  /** Check to see if a given panel type is visible */
  const isVisible = (panelId: string) => {
    return visiblePanels.indexOf(panelId) > -1;
  }

  /** Get the current segment number based on the object keys. */
  const currSegNum = Object.keys(tilesOrdered).indexOf(currSegment.toString())+1;

  /** Get the previous segment number based on the object keys. */
  const lastSegNum = Object.keys(tilesOrdered).length;

  const isLastSeg = currSegNum === lastSegNum;

  const handleAddTile = () => cardSorterRef.current?.handleAddNewTile()

  const handleOrderChange = (event: React.MouseEvent<HTMLElement>, newOrder: string) => {
    if (newOrder != null) {
      setTileOrder(newOrder)
    }
  }

  const leftColWidth = () => {
    return visiblePanels.length > 0 ? 'calc(100vw - 600px)' : '100%';
  }

  const rightPanelHeight = () => {
    const h = 80 / visiblePanels.length + 'vh';
    // log.info(`Calculate height as ${h}`);
    return h;
  }

  return (
    <>
      <RootPanel data-testid='hg-root-panel'>
        <LeftColumn data-testid='hg-left-column' sx={{ maxWidth:  leftColWidth(), minWidth: leftColWidth()}}>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.4em'
            }}>{document ? `"${document.title}" by ${document.author}` : 'Loading...'}
          </Typography>
          <Grid container direction="row" justifyContent="start" alignItems="center" sx={{ pb: 1, pt: 0 }}
                data-testid='hg-left-col-toolbar-grid'>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Tooltip title="Save your edits" arrow>
                <IconButton color={isSaving ? 'default' : 'secondary'} aria-label="save" onClick={handleSaveClick} data-testid='save-tiles-button' disabled={isUIControlDisabled()} >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              {isSaving && (
                <CircularProgress
                  size={45}
                  color="secondary"
                  sx={{
                    position: 'absolute',
                    top: -2,
                    left: -2,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Tooltip title="Add a new tile" arrow>
              <IconButton color="secondary" aria-label="add tile" onClick={handleAddTile} data-testid='add-new-tile-button'  disabled={isUIControlDisabled()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Tooltip title="Discard changes and reload document" arrow>
                <IconButton color="secondary" aria-label="reload" onClick={handleReloadClick} data-testid='reload-button' disabled={isUIControlDisabled()}>
                  <CachedIcon />
                </IconButton>
              </Tooltip>
              {isReloading && (
                <CircularProgress
                  size={45}
                  color="secondary"
                  sx={{
                    position: 'absolute',
                    top: -2,
                    left: -2,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>

            {/*
                <Grid item container xs direction="row" alignItems="center" justifyContent="start" sx={{flexWrap: 'nowrap'}}>
          // TODO Re-enable when we have more targeted matching based on TL term.
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingInline: '0.5em' }}>
            <Typography>{'Suggest Translation?'}</Typography>
            <Switch data-testid="autocomplete-switch"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}/>

          </div>
          */

            }
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: '10px',
              border: 'solid 1px #ddd',
              borderRadius: '4px',
              whiteSpace: 'nowrap'
            }}>
              <Typography sx={{mr: 1, fontSize: '12pt'}}>{'Tile Order: '}</Typography>
              <ToggleButtonGroup size="small"
                                 color="secondary"
                                 value={tileOrder}
                                 onChange={handleOrderChange}
                                 exclusive
                                 aria-label="Tile Order"
              >
                <ToggleButton color="secondary" value="tl" aria-label="Source - {tl_lang.trigraph}"
                              sx={{
                                border: 'solid 1px',
                                '&.Mui-selected': {
                                  borderRadius: 1,
                                  border: 'solid 1px',
                                  borderColor: 'secondary.main'
                                }
                              }}>
                  <Tooltip title={`Order words by source - ${getLanguage()}`}>
                    <span>Source/{getTrigraph()}</span>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton color="secondary" value="eng" aria-label="Translation"
                              sx={{
                                border: 'solid 1px',
                                '&.Mui-selected': {
                                  borderRadius: 1,
                                  border: 'solid 1px',
                                  borderColor: 'secondary.main'
                                }
                              }}>
                  <Tooltip title={`Order words by translation - English`}>
                    <span>Translation</span>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: '10px',
              border: 'solid 1px #ddd',
              borderRadius: '4px',
              marginLeft: '10px',
              whiteSpace: 'nowrap'
            }}>
              <Typography sx={{ mr: 1, fontSize: '12pt' }}>{'Show/Hide:'}</Typography>
              <ToggleButtonGroup size="small"
                                 color="secondary"
                                 aria-label="Show/Hide"
                                 value={visiblePanels}
                                 onChange={(e, newVisible) => {setVisiblePanels(newVisible)}}
              >
                { Object.keys(refTiles).length > 0 &&
                  <ToggleButton color="secondary" value={show_ref_tl} aria-label="Show/hide reference source"
                                size="small"
                                sx={{
                                  border: 'solid 1px',
                                  '&.Mui-selected': {
                                    borderRadius: 1,
                                    border: 'solid 1px',
                                    borderColor: 'secondary.main'
                                  }
                                }}>
                    <Tooltip title={`Show/hide reference source ${getLanguage()}`}>
                      <span>Ref {getTrigraph()}</span>
                    </Tooltip>
                  </ToggleButton>
                }
                { Object.keys(refTiles).length > 0 &&
                  <ToggleButton color="secondary" value={show_ref_en} aria-label="Show/Hide Reference Translation"
                                size="small"
                                sx={{
                                  border: 'solid 1px',
                                  '&.Mui-selected': {
                                    borderRadius: 1,
                                    border: 'solid 1px',
                                    borderColor: 'secondary.main'
                                  }
                                }}>
                    <Tooltip title={`Show/hide reference translation`}>
                      <span>Ref ENG</span>
                    </Tooltip>
                  </ToggleButton>
                }
                { document.mt_allowed &&
                  <ToggleButton color="secondary" value={show_mt_en} aria-label="Show/Hide Machine Translation" size="small"
                                sx={{
                                  border: 'solid 1px',
                                  '&.Mui-selected': {
                                    borderRadius: 1,
                                    border: 'solid 1px',
                                    borderColor: 'secondary.main'
                                  }
                                }}>
                    <Tooltip title={`Show/hide machine translation`}>
                      <span>MT ENG</span>
                    </Tooltip>
                  </ToggleButton>
                }
                <ToggleButton color="secondary" value={show_my_tl} aria-label="Show/hide my source" size="small"
                              sx={{
                                border: 'solid 1px',
                                '&.Mui-selected': {
                                  borderRadius: 1,
                                  border: 'solid 1px',
                                  borderColor: 'secondary.main'
                                }
                              }}>
                  <Tooltip title={`Show/hide my segment source ${getLanguage()}`}>
                    <span>My {getTrigraph()}</span>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton color="secondary" value={show_my_en} aria-label="Show/hide my translation" size="small"
                              sx={{
                                border: 'solid 1px',
                                '&.Mui-selected': {
                                  borderRadius: 1,
                                  border: 'solid 1px',
                                  borderColor: 'secondary.main'
                                }
                              }}>
                  <Tooltip title={`Show/hide my segment translation`}>
                    <span>My ENG</span>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingInline: '0.5em'}}>
              <Tooltip title={<KeyboardShortcuts/>}><InfoIcon color="info"/></Tooltip>
            </div>
          </Grid>

          <Paper data-testid='HG-cardsorter-paper' sx={{
            p: 2,
            flexGrow: '1',
            textAlign: 'center',
            overflow: 'hidden',
            overflowY: 'scroll',
            alignItems: 'center',
            padding: 2,
          }} elevation={4}>
            {!languageObj || getSegmentTLText() === 'Select a sentence' ? 'Select a sentence below!' :
              <CardSorter ref={cardSorterRef}
                          tl_lang={languageObj} sentence={getSegmentTLText()}
                          segId={currSegment ? currSegment : 1}
                          tilesDict={tilesDict}
                          setTilesDict={setTilesDict}
                          tileIds={tilesOrdered[currSegment].tileIds}
                          updateTile={updateTile}
                          updateTileIds={handleUpdateTileIds}
                          originalTilesRef={originalTilesRef}
                          handleMoveTile={handleMoveTile}
                          isUIControlDisabled={isUIControlDisabled()}
                          tileOrder={tileOrder}
              />}
          </Paper>


          {/*
            // RHB 2024.03.28: Removed to match new translation UI for experiment. This makes a table of all segments
            //                 TODO Use or remove.
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '50vh', overflow: 'scroll' }}>
              {makeGrid()}
            </Paper>
            */}
          <Grid sx={{padding: '0px', maxHeight: '60px'}} item container xs direction="row" alignItems="center"
                justifyContent="space-between" data-testid='HG-navigation-grid'>
            <Box sx={{m: 1, position: 'relative'}} data-testid='HG-nav-next-box'>
              <Tooltip title="Save changes and go to the previous segment" arrow>
                  <span>
                    <Fab color={isMoveNext ? "default" : "primary"} onClick={handlePrevClick} data-testid='prev-button'
                         disabled={currSegNum === 1 || isUIControlDisabled()}>
                      <BeforeIcon fontSize="large"/>
                    </Fab>
                  </span>
              </Tooltip>
              {isMovePrev && (<CircularProgress size={60} color="primary" sx={{ position: 'absolute', top: -1, left: -1, zIndex: 1 }}/>)}
            </Box>
            <Typography fontSize="large" data-testid='HG-nav-seg-lbl'>Segment {currSegNum} of {lastSegNum}</Typography>
            {isLastSeg ? (
              <Box sx={{ m: 1, position: 'relative' }} data-testid='HG-nav-complete-box'>
                <Tooltip title="Save changes and complete the translation" arrow>
                  <Fab color={isMoveNext ? "default" : "primary"} aria-label="complete translation" onClick={handleCompleteClick}
                       data-testid='complete-button' disabled={isUIControlDisabled()}>
                    <CheckIcon fontSize="large"/>
                  </Fab>
                </Tooltip>
                {isMoveNext && (<CircularProgress size={60} color="primary" sx={{ position: 'absolute', top: -1, left: -1, zIndex: 1 }}/>)}
              </Box>
            ) : (
              <Box sx={{ m: 1, position: 'relative' }} data-testid='HG-nav-prev-box'>
                <Tooltip title="Save changes and go to the next segment" arrow>
                  <Fab color={isMoveNext ? "default" : "primary"} aria-label="next segment" onClick={handleNextClick}
                       data-testid='next-button' disabled={isUIControlDisabled()} sx={{ zIndex: 0}}>
                    <NextIcon fontSize="large"/>
                  </Fab>
                </Tooltip>
                {isMoveNext && (<CircularProgress size={60} color="primary" sx={{ position: 'absolute', top: -1, left: -1, zIndex: 1 }}/>)}
              </Box>
            )}
          </Grid>
        </LeftColumn>
        { // Only make the Right column when at least one panel is visible.
          visiblePanels.length > 0 && (
          <RightColumn data-testid='hg-right-column'>
            <Stack spacing={3} sx={{ maxWidth: '500px', width: '500px', minWidth: '100px' }}>
              {Object.keys(refTiles).length > 0 && isVisible(show_ref_tl) &&
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: rightPanelHeight() }} elevation={4} >
                  <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: 1, borderColor: 'grey.400'}}>
                    <Typography variant="h6" sx={{ textAlign: 'center', wordWrap: 'none'}}>Reference Source {getLanguage()}</Typography>
                  </Box>
                  <Typography dir={getDirection()} sx={{ textAlign: getAlignment(), pt: 1, overflow: 'scroll', width: '100%', height: '100%' }}>{getSegmentReferenceTLText()}</Typography>
                </Paper>
              }
              {Object.keys(refTiles).length > 0 && isVisible(show_ref_en) &&
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: rightPanelHeight() }} elevation={4} >
                  <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: 1, borderColor: 'grey.400'}}>
                    <Typography variant="h6" sx={{ textAlign: 'center', wordWrap: 'none'}}>Reference Translation</Typography>
                  </Box>
                  <Typography dir='ltr' sx={{ textAlign: 'left', pt: 1, overflow: 'scroll', width: '100%', height: '100%' }}>{getSegmentReferenceEnText()}</Typography>
                </Paper>
              }
              {document.mt_allowed && isVisible(show_mt_en) &&
                <Paper sx={{ p: 2, display: 'flex', justifyContent: 'spaceBetween', flexDirection: 'column', height: rightPanelHeight() }}  elevation={4} >
                  <Typography variant="h6" sx={{ textAlign: 'left', pb: 1, borderBottom: 1, borderColor: 'grey.400' }}>Machine Translation of Edited Source</Typography>
                  <Typography dir="ltr" sx={{ textAlign: 'left', pt: 1, overflow: 'scroll', width: '100%', height: '100%'}}>{translatedText}</Typography>
                  <Typography variant="h6" sx={{ textAlign: 'center', borderTop: 1, borderColor: 'grey.400', mb: 0, pt: 1 }}>
                    <img src={process.env.PUBLIC_URL + '/img/gt-color-regular.png'} alt="Translated by Google"/>
                  </Typography>
                </Paper>}
              {isVisible(show_my_tl) &&
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: rightPanelHeight() }} elevation={4} >
                  <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: 1, borderColor: 'grey.400'}}>
                    <Typography variant="h6" sx={{ textAlign: 'center', wordWrap: 'none'}}>Edited Source {getLanguage()}</Typography>
                    { document?.mt_allowed && makeTranslateButton() }
                  </Box>
                  <Typography dir={getDirection()}
                              sx={{ textAlign: getAlignment(), pt: 1, overflow: 'scroll', width: '100%', height: '100%'}}>{getSegmentTLText()}</Typography>
                </Paper>

              }
              {isVisible(show_my_en) &&
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: rightPanelHeight() }} elevation={4} >
                  <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: 1, borderColor: 'grey.400'}}>
                    <Typography variant="h6" sx={{ textAlign: 'center', wordWrap: 'none'}}>Edited Translation</Typography>
                  </Box>
                  <Typography dir='ltr' sx={{ textAlign: 'left', pt: 1, overflow: 'scroll', width: '100%', height: '100%' }}>{getSegmentEnText()}</Typography>
                </Paper>
              }
            </Stack>
          </RightColumn>
        )
        }
      </RootPanel>
    </>
  );
};

export default HyperglossEditCmp
