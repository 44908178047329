/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * The main App file for ALEF. Contains the router, config, and provider/wrappers that provide context for all views.
 * @module
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from './config/Theme';
import { default_config, dynamic_config_url, DynamicConfig } from './config/app_config';
import { AuthProvider } from './providers/Auth';
import { CookiesProvider } from 'react-cookie';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './views/Login';
import Signup from './views/Signup';
import Profile from './components/Profile/Profile'
import RequireAuth from './components/RequireAuth';
import CssBaseline from '@mui/material/CssBaseline';
import MainViewLayout from './views/MainViewLayout';
// import Lexicon from './views/main/Lexicon';
import DocumentViewLayout from './views/main/DocumentViewLayout';
import Waiting from './components/Waiting';
import DocumentTwoColumnView from './views/main/DocumentTwoColumnView';
import DocumentReaderView from './views/main/DocumentReaderView';
import HyperglossEditView from './views/main/HyperglossEditView';
import Documents from './views/main/Documents';
// import Grammar from './views/main/Grammar';
// import Idafa from './views/main/Idafa';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AddDocument from './views/main/AddDocument';
import Confirm from './views/Confirm';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import NotFound from './views/NotFound';
import Landing from './views/main/Landing';
import { doGet } from './services/util';
import { getLogger, setupAppConfig } from './config/LogConfig';
import { useConfig } from './providers/Config';


const log = getLogger('components.hyperglossEditCmp');

let routes = [
  { path: '/login', element: <Login/> },
  { path: '/forgot-password', element: <ForgotPassword/> },
  { path: '/reset-password', element: <ResetPassword/> },
  { path: '/signup', element: <Signup/> },
  { path: '/confirm', element: <Confirm/> },
  {
    path: '/', element:
      <RequireAuth>
        <MainViewLayout/>
      </RequireAuth>,
    children: [
      {
        path: '/',
        element:
          <RequireAuth>
            <Landing/>
          </RequireAuth>,
      },
      {
        path: '/documents',
        element:
          <RequireAuth>
            <Documents/>
          </RequireAuth>,
      },
      {
        path: '/documents/:documentId',
        element:
          <RequireAuth>
            <DocumentViewLayout/>
          </RequireAuth>,
        children: [
          {
            path: 'two-column',
            element:
              <DocumentTwoColumnView/>
          },
          {
            path: 'reader',
            element:
              <DocumentReaderView/>
          },
          {
            path: 'translation/:initialSegId',
            element:
              <HyperglossEditView/>
          },
        ]
      },
      {
        path: 'documents/add-document',
        element:
          <RequireAuth>
            <AddDocument/>
          </RequireAuth>
      },
      { path: '/user', element: <RequireAuth><Profile/></RequireAuth> },
      
      // {
      //   path: '/grammar',
      //   element:
      //     <RequireAuth>
      //       <Grammar/>
      //     </RequireAuth>
      // },
      // {
      //   path: '/lexicon',
      //   element:
      //     <RequireAuth>
      //       <Lexicon/>
      //     </RequireAuth>
      // },
      // {
      //   path: '/idafa',
      //   element:
      //     <RequireAuth>
      //       <Idafa/>
      //     </RequireAuth>
      // },
    ]
  },
  {
    path: '*',
    element: <NotFound/>
  }
]

const App = () => {
  const [configLoadingState, setConfigLoadingState] = useState<'loading' | 'ready' | 'error'>('loading');
  const { setConfig, config } = useConfig();
  
  useEffect(() => {
    doGet(dynamic_config_url, (response) => {
      let c = response as DynamicConfig
      setConfig(c);
      // log.info(`Global config fetched: ${JSON.stringify(response)}`);
      setupAppConfig(c);
      setConfigLoadingState('ready');
    }, (err) => {
      if (process.env.NODE_ENV === 'development') {
        log.warn(`Failed to load global configuration from '${dynamic_config_url}', using the default configuration:`, default_config);
        setupAppConfig(default_config);
        setConfigLoadingState('ready');
      } else {
        setConfigLoadingState('error');
      }
    });
  }, [setConfig]);
  
  if (configLoadingState === 'loading') {
    return <p>loading...</p>
  }
  
  if (configLoadingState === 'error') {
    log.error(`Got an error looking up config at : ${dynamic_config_url}`)
    return <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config </p>;
  }
  
  if (!config.signupEnabled) {
    routes = routes.filter(route => route.path !== '/signup')
  }
  
  const router = createBrowserRouter(routes)
  
  return (
    <>
      <CookiesProvider>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={appTheme}>
            <CssBaseline/>
            <AuthProvider>
              <RouterProvider router={router} fallbackElement={<Waiting/>}/>
            </AuthProvider>
          </ThemeProvider>
        </DndProvider>
      </CookiesProvider>
    </>
  )
};

export default App;
