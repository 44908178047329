/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Not Found view for when ALEF user hits 404
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import packageJson from '../../package.json'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const nav = useNavigate()
  
  return (
    <Backdrop open={true} sx={{ overflowY: 'auto' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper sx={{ paddingY: 8, paddingX: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography align="center" mb={2} mt={2} variant="h4">
            404 NOT FOUND
          </Typography>
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography sx={{ pt: 1, width: 'fit-content' }}>
            You have navigated to a page that does not exist.
          </Typography>
          <Button variant={'text'} sx={{ mt: 2, p: 2 }} style={{ width: 'fit-content' }} onClick={() => nav('/login')}>
            <Typography>Click here to return home</Typography>
          </Button>
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography align="center">
            ALEF v{packageJson.version}
          </Typography>
        </Paper>
      </Container>
    </Backdrop>
  )
}

export default NotFound
