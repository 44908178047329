/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Landing Page for ALEF.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const TDH = styled('td')(() => ({
  textAlign: 'right',
  fontSize: 14
}));

const TDD = styled('td')(() => ({
  textAlign: 'left',
  fontSize: 14
}));

const Landing = () => {
  const vers = process.env.REACT_APP_ALEF_VERSION ? process.env.REACT_APP_ALEF_VERSION : 'Unknown Version'
  const branch = process.env.REACT_APP_ALEF_BRANCH ? process.env.REACT_APP_ALEF_BRANCH : 'Unknown Branch'
  const sha = process.env.REACT_APP_ALEF_SHA ? process.env.REACT_APP_ALEF_SHA : 'Unknown SHA'
  const build_date = process.env.REACT_APP_BUILD_DATE ? process.env.REACT_APP_BUILD_DATE : 'Unknown Date'
  const build_by = process.env.REACT_APP_BUILD_BY ? process.env.REACT_APP_BUILD_BY : 'Unknown Creator'
  
  return (
    <>
      <Typography variant="h2" sx={{ fontSize: 248, py: 5, textAlign: 'center' }}>א‎</Typography>
      <Typography variant="h2" sx={{ py: 5, textAlign: 'center' }}>Welcome to ALEF!</Typography>
      <Typography variant="h4" sx={{ py: 5, textAlign: 'center' }}>Select documents above to start a Deep Translation</Typography>
      <div style={{ position: 'absolute', bottom: '0px', padding: '20px', right: '0px' }}>
        <Tooltip title={
          <React.Fragment>
            <Typography color="inherit" sx={{ textAlign: 'center' }}>ALEF Version</Typography>
            <table style={{ padding: '20px' }} cellSpacing="5">
              <tr><TDH>Version</TDH><TDD>{vers}</TDD></tr>
              <tr><TDH>Branch</TDH><TDD>{branch}</TDD></tr>
              <tr><TDH>SHA</TDH><TDD>{sha}</TDD></tr>
              <tr><TDH>Build Date</TDH><TDD>{build_date}</TDD></tr>
              <tr><TDH>Built By</TDH><TDD>{build_by}</TDD></tr>
            </table>
          </React.Fragment>
        }>
          <InfoIcon fontSize="large"/>
        </Tooltip>
      </div>
    </>
  
  );
};

export default Landing
