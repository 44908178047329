/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Personalized documents table for a users profile.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { Container } from '@mui/material';
import { User } from '../../../model/user'
import { DocumentService } from '../../../services/document_service';
import { Document } from '../../../model/alef_model';
import DocumentGrid2 from '../../DocumentGrid2';
import { useConfig } from '../../../providers/Config';
import { getLogger } from '../../../config/LogConfig';

interface MyDocumentsProps {
  user: User
}

const MyDocuments = ({ user }: MyDocumentsProps) => {
  const log = getLogger('components.profile.content.MyDocuments');
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const [documents, setDocuments] = React.useState<Array<Document>>([]);
  
  React.useEffect(() => {
    const do_lookup = async () => {
      log.info(`Looking up documents for user: ${user.user_id}`)
      await doc_service.documentsByUser((docs) => setDocuments(docs),
        (msg) => console.error(`Got an error on document lookup: ${msg}`));
    }
    do_lookup();
  }, [user.user_id, doc_service, log]);
  
  // TODO: Fix document render links to link to paths not relative to document page
  // TODO: Documents loaded from new document table. Need to migrate rest of application to use new documents table
  return (
    <Container>
      <DocumentGrid2 documents={documents} shared={true}/>
    </Container>
  );
};


export default MyDocuments
