/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Login Page.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { useAuth } from '../providers/Auth';
import { styled } from '@mui/material/styles';

import {
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { getLogger } from '../config/LogConfig';
import Waiting from '../components/Waiting';
import packageJson from '../../package.json';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useConfig } from '../providers/Config';
import { Tooltip } from '@mui/material';


const log = getLogger('views.Login');
const vers = process.env.REACT_APP_ALEF_VERSION ? process.env.REACT_APP_ALEF_VERSION : packageJson.version

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.background.paper
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50ch'
  },
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  paddingInline: theme.spacing(6)
}))

const Login = () => {
  const { config } = useConfig();
  let auth = useAuth();
  let location = useLocation();
  let navigate = useNavigate();
  const [loaded, setLoaded] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [usernameInvalid, setUsernameInvalid] = React.useState(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const [usernameErr, setUsernameErr] = React.useState('');
  
  const navigatePathname = React.useMemo(() => {
    let state = location.state as { from: Location };
    // temporary nav to documents page as there is no landing page
    return (state && state.from && state.from.pathname !== '/') ? state.from : '/Documents';
  }, [location]);
  
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const updateUser = (name: string) => {
    setUsernameErr('')
    setUsername(name);
    setUsernameInvalid(name.length <= 0)
  }
  
  const updatePassword = (name: string) => {
    setPassword(name);
    setPasswordInvalid(name.length <= 0)
  }
  
  const handleKeydown = (key: string) => {
    if (key === 'Enter') {
      handleLogin()
    }
  }
  const handleLogin = async () => {
    updateUser(username);
    updatePassword(password);
    if (username.length > 0 && password.length > 0) {
      log.info('Making login. About to test connection!');
      // TestService.test_public();
      auth.login(username, password,
        (user) => navigate(navigatePathname, { replace: true }),
        (errMsg) => {
          const msg = errMsg !== '' ? errMsg : 'Unknown error!';
          setUsernameInvalid(true);
          setPasswordInvalid(true);
          setUsernameErr(msg);
        });
    }
  };
  
  const handleResendConfirmation = () => {
    if (username.length > 0 && password.length > 0) {
      log.info('Resending email confirmation to user: ', username)
      console.log('Resending email confirmation to user: ', username)
      auth.resendConfirmation(username,
        (msg) => {
          console.log('Success', msg)
          navigate('/confirm', { replace: true })
        },
        (msg) => {
          console.log('Failure', msg)
        },
      )
    }
  }
  
  React.useEffect(() => {
    const asyncCallback = async () => {
      log.info('Calling restore session!');
      const cb = () => setLoaded(true);
      await auth.restore_session(cb, cb);
    }
    
    if (!auth.user) {
      asyncCallback()
    } else {
      log.info('Setting loaded!');
      setLoaded(true);
    }
  }, [auth]);
  
  // Force return to main page when user is already logged in.
  // log.info(`Checking auth user! ${auth.user}`);
  if (!loaded) {
    // Need to restore the session
    log.info('Not loaded. Show wait! ');
    return <Waiting/>
  }
  if (auth.user) {
    log.info(`Found an auth user. Going to documents or $navigatePathname!`);
    return (<Navigate to="/documents" replace/>);
  } else {
    return (
      <>
        <Backdrop open={true}>
          <StyledFormControl>
            <Typography align="center" mb={2} mt={2} variant="h4">
              ALEF Login
            </Typography>
            <Divider/>
            
            <StyledTextField
              variant="filled"
              required={true}
              label="User ID"
              error={usernameInvalid}
              helperText={usernameErr}
              onKeyDown={(e) => handleKeydown(e.key)}
              onChange={(event) => updateUser(event.target.value.trim())}
              onBlur={(event) => updateUser(event.target.value.trim())}
              autoFocus={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon/>
                  </InputAdornment>
                ),
              }}
            />
            {usernameErr === 'Please confirm your email address' ? <Link component="button" onClick={handleResendConfirmation}>
              Resend email verification
            </Link> : null}
            
            
            <StyledTextField
              required={true}
              variant="filled"
              label="Password"
              error={passwordInvalid}
              type={showPassword ? 'text' : 'password'}
              onKeyDown={(e) => handleKeydown(e.key)}
              onChange={(event) => updatePassword(event.target.value.trim())}
              onBlur={(event) => updatePassword(event.target.value.trim())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={handleShowPassword}>
                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%', mb: 2 }}>
              <Tooltip title={!config.signupEnabled ? 'Signup is currently disabled' : null} placement="bottom" arrow>
                <span>
                  <StyledButton color="primary" variant="outlined" fullWidth={false} endIcon={<AppRegistrationIcon/>}
                                onClick={() => navigate('/signup')}
                                disabled={!config.signupEnabled}>
                    Sign Up
                  </StyledButton>
                </span>
              </Tooltip>
              <StyledButton color="primary" variant="outlined" fullWidth={false} endIcon={<LoginIcon/>}
                            onClick={handleLogin}>
                Sign In
              </StyledButton>
            </Stack>
            
            <Grid container justifyContent="flex-end">
              <Grid item sx={{ mr: 4 }}>
                <Link component="button" variant="body2" onClick={() => {
                  navigate('/forgot-password')
                }}>
                  Forgot Password?
                </Link>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }}/>
            <Typography align="center" mb={2} mt={2}>
              ALEF v{vers}
            </Typography>
          
          </StyledFormControl>
        </Backdrop>
      </>
    );
  }
};

export default Login
