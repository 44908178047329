/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Documents - The primary page for ALEF. Display a table of documents for the user to select and begin the learning exercise.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import React from 'react';
import { Typography } from '@mui/material';
import DocumentGrid from '../../components/DocumentGrid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Document } from '../../model/alef_model';
import { DocumentService } from '../../services/document_service';
import { getLogger } from '../../config/LogConfig';
import Waiting from '../../components/Waiting';
import { useConfig } from '../../providers/Config';

const Documents = () => {
  const nav = useNavigate();
  const { config } = useConfig();
  const doc_service = React.useMemo(() => new DocumentService(config), [config]);
  const log = getLogger('views.main.Documents');
  
  log.info('Gathering documents from alef!')
  const [documents, setDocuments] = React.useState<Array<Document>>([]);
  const hasDoneLookup = React.useRef(false)
  const hasFinishedLookup = React.useRef(false)
  
  React.useEffect(() => {
    const do_lookup = async () => {
      log.info('Do lookup');
      hasDoneLookup.current = true
      await doc_service.documents((docs) => setDocuments(docs),
        (msg) => console.error(`Got an error on document lookup: ${msg}`));
    }
    if (!hasDoneLookup.current) do_lookup().then(() => {
      hasFinishedLookup.current = true
    });
    return () => {
    };
  }, [log, doc_service]);
  
  /**
   * Updates the documents state array using the setDocuments function
   * @param updatedDocuments the new documents to be set.
   */
  const updateDocuments = (updatedDocuments: Document[]): void => {
    setDocuments(updatedDocuments)
  }
  
  const handleClick = () => {
    nav('/documents/add-document')
  }
  
  /**
   * Removes a document with the specified id from the state.
   * @param id the id of the document to remove from the state
   */
  const handleRemovedDocument = React.useCallback((id: number): void => {
    const newDocuments: Document[] = [...documents].filter(doc => doc.id !== id)
    setDocuments(newDocuments)
  }, [documents])
  
  if (!hasFinishedLookup.current) return (<Waiting/>)
  
  return (
    <>
      <Typography variant="h5" sx={{ pt: 6, pb: 2, textAlign: 'center' }}>Select a Document to start a Deep Translation</Typography>
      <Button variant="outlined" sx={{ marginInline: '1.5em', marginBottom: '1em', padding: '0.5em' }} onClick={handleClick}>Add
        Document</Button>
      <DocumentGrid documents={documents} updateDocuments={updateDocuments} handleRemovedDocument={handleRemovedDocument}/>
    </>
  
  );
};

export default Documents
