/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Password Reset Confirmation Page.
 * @module
 * @author Christopher Sadka <a href="mailto:chris.sadka@steelcutsoftware.com">chris.sadka@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../providers/Auth';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import packageJson from '../../package.json'

const Confirm = () => {
  const nav = useNavigate()
  let auth = useAuth()
  const [queryParams] = useSearchParams()
  const token = queryParams.get('token')
  const username = queryParams.get('username')
  
  const [message, setMessage] = useState<String>('')
  
  
  // renders twice due to react strict mode in index.tsx
  useEffect(() => {
    if (!auth) return
    if (username) {
      auth.resendConfirmation(username,
        (msg) => {
          console.log('Successful: ', msg)
          setMessage('Please check your email for a confirmation link.')
        }, (msg) => {
          console.log('Failure: ', msg)
          if (msg === 'user is already confirmed.') {
            setMessage('Your email is already confirmed.')
          }
        })
    } else {
      if (!token) {
        setMessage('Please check your email for a confirmation link.')
        return
      }
      console.log('Token: ', token)
      auth.confirm(token,
        (msg) => {
          console.log('Confirmation successful: ', msg)
          setMessage('You have confirmed your account.')
        }, (msg) => {
          console.log('Confirmation fail: ', msg)
          if (msg === 'Token has expired.') {
            setMessage('Token has expired.')
          }
        })
    }
    
  }, [auth, token, username])
  
  return (
    <Backdrop open={true} sx={{ overflowY: 'auto' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper sx={{ paddingY: 8, paddingX: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography align="center" mb={2} mt={2} variant="h4">
            ALEF Account Confirmation
          </Typography>
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography sx={{ pt: 1, width: 'fit-content' }}>
            {message}
          </Typography>
          {token && message !== 'Token has expired.' &&
            <Button variant={'text'} sx={{ mt: 2, p: 2 }} style={{ width: 'fit-content' }} onClick={() => nav('/login')}>
              <Typography>Click here to login</Typography>
            </Button>
          }
          
          {/*{message === 'Token has expired.' &&*/}
          {/*  <Button variant={'text'} sx={{ mt: 2, p: 2 }} style={{ width: 'fit-content' }} onClick={() => nav('/confirm?username=')}>*/}
          {/*    <Typography>Click here request a new token</Typography>*/}
          {/*  </Button>*/}
          {/*}*/}
          <Divider sx={{ mt: 3, mb: 2, width: '115%' }}/>
          <Typography align="center">
            ALEF v{packageJson.version}
          </Typography>
        </Paper>
      </Container>
    </Backdrop>
  )
}

export default Confirm
