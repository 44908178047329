/*
 * ===============================================================================
 *
 * DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government Agencies
 * and their contractors; 2022. Other request for this document shall be referred
 * to AF 517 TRG.
 *
 * WARNING: This document may contain technical data whose export is restricted by
 * the Arms Export Control Act (AECA) or the Export Administration Act
 * (EAA). Transfer of this data by any means to a non-US person who is not eligible
 * to obtain export-controlled data is prohibited. By accepting this data, the
 * consignee agrees to honor the requirements of the AECA and EAA. DESTRUCTION
 * NOTICE: For unclassified, limited distribution documents, destroy by any method
 * that will prevent disclosure of the contents or reconstruction of the document.
 *
 * This material is based upon work supported under Air Force Contract
 * No. FA8721-05-C-0002 and/or FA8702-15-D-0001. Any opinions, findings,
 * conclusions or recommendations expressed in this material are those of the
 * author(s) and do not necessarily reflect the views of the U.S. Air Force.
 *
 * © 2023 Massachusetts Institute of Technology.
 *
 * The software/firmware is provided to you on an As-Is basis
 *
 * Delivered to the US Government with Unlimited Rights, as defined in DFARS Part
 * 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
 * U.S. Government rights in this work are defined by DFARS 252.227-7013 or DFARS
 * 252.227-7014 as detailed above. Use of this work other than as specifically
 * authorized by the U.S. Government may violate any copyrights that exist in this
 * work.
 * ===============================================================================
 */
/**
 * Log config for ALEF.
 * @module
 * @author Raymond Budd <a href="mailto:raymond.budd@steelcutsoftware.com">raymond.budd@steelcutsoftware.com</a>
 * @since v0.2.1, December 11, 2023
 * @copyright Copyright &copy; 2023 Massachusetts Institute of Technology, Lincoln Laboratory
 */

/*--- config/LogConfig.ts ---*/
import log from 'loglevel';
import { CoreService } from '../services/core_service';
import { ClientMessage } from '../model/core_model';
import { DynamicConfig } from '../config/app_config';

// Override the default factory to format messages with date, level, and logger name.
// Note the level provided by the logLevel argument is the configured level, not the level of the message.
//

var myConfig: DynamicConfig;

export function setupAppConfig(newAppConfig: DynamicConfig) {
  myConfig = newAppConfig;
  console.log(`Setup app config as ${JSON.stringify(myConfig)}`);
  if (myConfig.logEnabled) {
    console.log(`Enabling logs`);
    log.enableAll(false);
  } else {
    console.log(`Disabling logs`);
    log.disableAll(false);
  }
  console.log(`Set default log level to ${myConfig.logLevel}`);
  log.setLevel(myConfig.logLevel);
}

var originalFactory = log.methodFactory;

log.methodFactory = function (methodName, logLevel, loggerName) {
  var rawMethod = originalFactory(methodName, logLevel, loggerName);
  var mailQueue: ClientMessage[] = []
  var isSending = false;
  
  // If we have a loggerName, and it has a description use it. If we don't have a description
  const lname = loggerName ? typeof loggerName === 'symbol' && typeof loggerName.description !== 'undefined' ? loggerName.description : loggerName.toString() : 'root';
  
  let enqueueMessage = (msg: ClientMessage) => {
    mailQueue.push(msg);
    sendAMessage();
  }
  
  let sendAMessage = () => {
    if (isSending || mailQueue.length <= 0) {
      return;
    }
    
    isSending = true;
    let msg = mailQueue.shift()
    if (typeof msg !== 'undefined') {
      console.log(`Make service with app config as ${JSON.stringify(myConfig)}`);
      if (myConfig) {
        let service = new CoreService(myConfig);
        service.logMail(msg,
          (r) => {
            isSending = false;
            setTimeout(sendAMessage, 0);
          },
          (e) => {
            // Note we should not set this to error (or any level that may be sent to the server. If done, it could introduce
            // an infinite loop of failure.
            console.log('Received error when attempting to send message', e)
          });
      } else {
        console.log('Not sending message due to missing config');
      }
    }
  }
  
  return (message) => {
    let timestamp = new Date();
    let lvl = methodName.toUpperCase();
    
    // This formats the messages for the console
    let full_msg = `${timestamp.toISOString()} [${lvl}] [${lname}] :> [${logLevel}] ${message}`;
    if ('ERROR' === lvl) {
      const vers = process.env.REACT_APP_ALEF_VERSION ? process.env.REACT_APP_ALEF_VERSION : 'Unknown Version'
      const branch = process.env.REACT_APP_ALEF_BRANCH ? process.env.REACT_APP_ALEF_BRANCH : 'Unknown Branch'
      const sha = process.env.REACT_APP_ALEF_SHA ? process.env.REACT_APP_ALEF_SHA : 'Unknown SHA'
      const build_date = process.env.REACT_APP_BUILD_DATE ? process.env.REACT_APP_BUILD_DATE : 'Unknown Date'
      const build_by = process.env.REACT_APP_BUILD_BY ? process.env.REACT_APP_BUILD_BY : 'Unknown Creator'
      
      console.log(vers + ' ' + branch + ' ' + sha + ' ' + build_date + ' ' + build_by);
      //let version = document.head.querySelector('[name~=version]').content
      let myErr = new Error();
      let myStack = myErr.stack ? myErr.stack : 'Unknown';
      enqueueMessage({
        'message': message, 'stacktrace': myStack, 'timestamp': timestamp,
        'logLevel': lvl, 'logName': lname, 'clientBuildVersion': vers, 'clientBuildBranch': branch,
        'clientBuildSha': sha, 'clientBuildDate': build_date, 'clientBuildBy': build_by
      });
    }
    rawMethod(full_msg);
  };
};

log.setLevel(log.getLevel()); // Be sure to call setLevel method in order to apply plugin
log.setDefaultLevel('trace');

// By default enable all logs (without calling this, tests were not logging any messages).
log.enableAll(false);
// Use commands like the following to disable or enable individual components
//log.getLogger("components.RequireAuth").enableAll(false)

export function getLogger(name: string) {
  return log.getLogger(name);
}
